.menus.mobile {
	position: fixed;
	height: calc(100% - #{$header-mobile-height});
	width: 100%;
	z-index: 100;
	display: block;
	background-color: transparent;
	top: $header-mobile-height; // height of mobile header
	transform:translateX(100%);
	transition: $transition;
	.mobile-menu-inner {
		background-color: white;
		max-width:100%;
		height: 100%;
		padding-bottom: 80px;
		margin-left: auto;
		overflow-y: auto;
		> .container {
			width:100%;
			padding-left:0;
			padding-right:0;
			max-width:none;
		}
	}
	.menu-open & {
		transform: translateX(0);
	}


	.mobile-menu-get-a-quote-button-wrap {
		width: 100%;
		display: block;
		margin-top:20px;
		.btn {
			padding:12px 20px;
		}
	}

	.mobile-utility-menu--container {
		background-color:$grey-med;
	}


	nav {
		#menu-main-menu {
			padding-bottom:150px;
		}
		&.mobile-utility-menu {
			padding:8px 0;
			ul {
				text-align: center;
				li {
					display: inline-block;
					margin:0 10px;
					a {
					    font-family: $pFont;
					    font-weight: 500;
					    text-transform: uppercase;
					    color: white;
					    letter-spacing: 1.44px;
					    font-size: 14px;
					    text-decoration: none;
					    transition: $transition;
					    line-height: 34px;
					}
				}
			}
		}
		ul {
			@include list();
			li {
				a {
					display: block;
					text-decoration:none;
				}
			}
		}
		&.nav-primary {
			> ul {
				> li {
					& + li {
						.link-wrapper {
							&:after {
								content:'';
								position:absolute;
								width: calc(100% + 55px);
								left:50%;
								transform:translateX(-50%);
								top:0;
								background-color:#bbc2c6;
								height: 1px;
							}
						}
					}
					.link-wrapper {
						position: relative;
						> a {
							padding:20px 27.5px;
							color:$grey-dark;
							font-size: 23.4px;
							transition: $transition;
							font-weight: 400;
							font-family: $pFont;
							position: relative;
							z-index: 2;
						}
					}
					&.has-mega,
					&.has-children {
						position: relative;
						&:before {
							content:'';
							position: absolute;
							right: 15px;
							top: 33px;
							transform: translateY(-50%) rotate(-90deg);
							@include iconFont;
							content: "\e007";
							font-size: 15px;
							transition: $transition;
							color:#bbc2c6;
						}
						&.active-sub {
							.link-wrapper {
								> a {
									font-weight: 500;
								}
							}
							&:before {
								margin-top: -4px;
								transform: rotate(90deg);
							}
							.subnav,
							.mega-menu {
								&:before,
								&:after {
									transition-delay:0.25s;
									left:30px;
								}
							}
						}
					}
				}
			}
			
			.subnav,
			.mega-menu {
				display: none;
				background-color: $off-white;
				border-top: 1px solid $grey-divider;
				position:relative;
				&:before,
				&:after {
					content:'';
					position:absolute;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 15px 15px 15px;
					
					left: -100px;
					z-index: 2;
					
					transition:$transition;
				}
				&:after {
					border-color: transparent transparent $off-white transparent;
					top: -15px;
				}
				&:before {
					border-color: transparent transparent $grey-divider transparent;
					top: -16px;
				}

				li {
					padding-top:5px;
					padding-bottom:5px;
					border-bottom:none;
					a {
						color:$grey-dark;
						font-size: 15px;
						font-weight: 500;
						padding-left: 25px;
						letter-spacing:1.28px;
						font-family: $pFont;
						text-transform: uppercase;
						transition:$transition;
						&:hover {
							color:$secondary;
						}
					}
					&:last-child {
						padding-bottom:30px;
					}
				}
			}
			.subnav {
				padding-top: 30px;
			}
			.mega-menu {
				.mega-nav--title {
					color:$primary;
					font-size: 25px;
					font-weight: 600;
					line-height: 1.28;
					letter-spacing: -0.5px;
				}
				.mega-menu--left-column-links-wrap {
					margin-left:-5px;
					margin-right: -5px;
					padding-bottom: 43px;
					border-bottom: 1px solid $grey-divider;
					.col-6 {
						padding-left:5px;
						padding-right:5px;
					}
					a.mega-menu--left-link-item {
						background: {
							size:cover;
							position:center;
						}
							padding-bottom:100%;
							width:100%;
							position:relative;
						&:before {
							content:'';
							position:absolute;
							top:0;
							left:0;
							height: 100%;
							width:100%;
							background-color:rgba(black,0.22);
							transition:background-color 0.3s ease;
						}
						&:hover {
							&:before {
								background-color:rgba(black,0.42);	
							}
						}
						.mega-menu--left-link-title {
							position:absolute;
							width: 100%;
							text-align: center;
							font-size: 33px;
							line-height: 1em;
							color:white;
							z-index: 1;
							top:50%;
							transform:translateY(-50%);
							font-weight: 600;
							line-height: 1.15;
							letter-spacing: -0.9px;
							font-family: $hFont;
						}
					}
				}
				.mega-menu--right-column-title {
					text-align: center;
				}
				.mega-menu--menu {
					text-align: center;
				}

				.mega-menu--menu_series {
					position: relative;
					margin-bottom:10px;
					padding-bottom:10px;
					display: inline-block;
					&:after {
						content:'';
						position:absolute;
						bottom:0;
						left:50%;
						transform:translateX(-50%);
						background-color: $grey-divider;
						height: 1px;
						width:calc(100% + 30px);

					}
					a {
						font-size: 23px;
						font-weight: 500;
						line-height: 1.39;
						text-transform: none;
					}
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	.admin-bar & {
		top: calc(32px + #{$header-mobile-height});
	}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		display: none;
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
	
}
