.menu-trigger-container {
	height: 100%;
	top: 50%;
	right: 0;
	transform:translateY(-50%);
	width: 50px;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	.menu-open & {
		
	}
}
.menu-trigger {
    display: block;
    position: absolute;
    height: auto;
    z-index: 4;
    transition: .3s;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
	text-decoration: none;
	color:#979797;
	font-size: 28px;
	.menu-open & {
		.icon-menu {
			&:before {
				content: "\";
				font-size: 18px;
				top: -2px;
				position: relative;
				left: 1px;
			}
		}
	}
}