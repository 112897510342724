.component--swatches {
	@include list();
	margin-top:10px;
	margin-left: -2px;
}

.component--swatch {
	display: inline-block;
	cursor: pointer;
	position: relative;
	padding: 2px;
	border: 2px solid transparent;
	transition: $transition;
	&:hover,
	&.active {
		border: 2px solid $primary;
	}
	div {
		width: 46px;
		height: 46px;
	}
}

.component--swatch_image {
	background: {
		size:cover;
		position:center;
		repeat:no-repeat;
	}
}

.dynamic-swatch-image-wrap {
	position: relative;
	width:100%;
	padding-bottom:86%;
	&.loading {
		&:after {
			content:'';
			position:absolute;
			top:0;
			left:0;
			top:0;
			left:0;
			background-color:rgba(255,255,255,1);
			background-image:url('../images/loading.gif');
		    height: 100%;
		    width: 100%;
		    background-repeat: no-repeat;
		    background-position: center;
		}
	}
	.dynamic-swatch-image {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height: 100%;
		background: {
			size:cover;
			position:center;
		}
		z-index: 1;
	}
}