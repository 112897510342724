.block.gallery-callout {
    --containerPadding: 27.5px;
    --dot-position: -75px;

    @include slick-round-buttons();

    position: relative;
    overflow: visible;
    display: flex;
    min-height: 600px;
    background-image: url(../images/rustic-series-banner.svg);

    .gallery-callout--gallery {
        order: 1;
        padding-left: 0;
        padding-right: 0;
    }

    .gallery-callout--callout-wrap {
        order: 2;
    }

    .gallery-callout--callout:before {
        top: -154px;
    }

    .gallery-callout--image {
        background-size: 0px;
    }

    &.gallery-callout--gallery-left {
        .gallery-callout--gallery {
            right: auto;
            left: 0;
        }

        .row {
            justify-content: flex-end;
        }
    }

    .slick-dots {
        transform: translateY(var(--dot-position));

        & > li:not(.slick-active) > button {
            background: $white;
        }
    }

    /* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {
        //
	}

    @include media-breakpoint-down(sm) {
        .container {
            max-width: none;
        }
	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
        .gallery-callout--gallery {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            max-width: 54%;
        }

        .gallery-callout--images {
            height: 100%;
    
            .slick-list,
            .slick-track,
            .slick-slide,
            .slick-slide > div,
            .gallery-callout--image {
                height: 100%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
    
            img {
                width: 100%;
                height: auto;
                opacity: 0;
            }
        }

        .block.basic-content {
            padding-top: 0;
        }
	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
        .slick-dots {
            --dot-position: -150px;
        }
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}

    @media (min-width: 2000px) {
        .gallery-callout--images {
			width: 100%;
			height: auto;
			aspect-ratio: 16 / 9;
		}
	}
}

.block.gallery-callout.is-block-editor {
    overflow: hidden;
    
    .gallery-callout--images {
        position: relative;
    
        img {
            position: absolute;
            inset: 0;
        }
    }
}