.utility-menu {
	position: relative;
	background-color:$grey-med;
	display: none;
	ul.nav {
		@include list();
		@extend .row;
		@extend .align-items-center;
		@extend .justify-content-end;
		height: 32px;
		li {
			display: inline-block;
			margin-right:20px;
			&.active {
				position:relative;
				&:after {
					content:'';
					position: absolute;
					bottom:-3px;
					left:0;
					height:3px;
					width:100%;
					background-color:$primary;
				}
			}
			&.utility-menu--search {
				a {
				    font-size: 13px;
				    vertical-align: -3px;
				}
			}
			&:last-child {
				margin-right: 0;
			}
			a {
				font-family: $pFont;
				font-weight: 400;
				text-transform: uppercase;
				color:white;
  				letter-spacing: 1.5px;
  				font-size:11px;
  				text-decoration: none;
  				transition:all 0.3s ease;
  				&:hover {
  					color:$secondary;
  				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {


	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

		display: block;
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}