.block.contact {
	position: relative;
	@include topDivider('white');
	padding-bottom:70px;
	padding-top: 0;
	.row-divider {
		position: relative;
		&:after {
			content:'';
			position: absolute;
			bottom:-70px;
			left:50%;
			width:calc(100% - 55px);
			height: 1px;
			background-color: $grey-divider;
			transform:translateX(-50%);
		}
	}
	
	.contact--form-wrap {
		padding:40px;
		border:1px solid $grey-divider;
		margin-bottom:30px;
	}

	.contact--form {
		p, li:not(.gfield) {
			max-width:450px;
			color:$grey-dark;
		}
	}

	.contact--form_disclaimer {
		font-size: 13px;
		color:$slate;
		line-height: 1.62;
	}

	.gform_footer {
		border-top: 1px solid $grey-divider;
		padding-top: 35px;
    	margin-top: 20px;
	}


	.contact--contact-details {
		border-bottom:1px solid $grey-divider;
		margin-bottom:35px;
	}

	.contact--phone,
	.contact--email {
		font-family: $pFont;
		font-weight: 500;
		line-height: 1.48;
		margin:0 0 25px 0;
		a {
			color:$grey-dark;
			text-decoration: none;
		}
	}

	ul.social-icons {
		margin-bottom: 25px;
		li {
			margin-right: 20px;
			&:last-child {
				margin-right: 0px;
			}
		}
		a {
			font-size:30px;
			color:$primary;
			&:hover {
				color:$secondary;
			}
		}
	}

	.contact--addresses {
		color:$grey-dark;
		h1,h2,h3,h4,h5,h6 {
			font-family: $pFont;
			margin:20px 0 10px;
			font-weight: 500;
		}
		p {
			margin:0;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {


	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
		padding-top: 60px;

		.contact--form-wrap {
			margin-bottom: 0;
		}
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}