.block.store-locator {
	position: relative;
	padding-top:100px;
	@include topDivider('white');
	.store-locator--intro {
		text-align: center;
	}

	.store-locator--title {
		font-family: $pFont;
		color:$grey-dark;
		margin:0 0 20px 0;
		font-weight: 500;
	}
	.store-locator--description {
		color:$grey-dark;
		font-size: 16px;
		line-height: 1.56;
	}

	.store-locator--locator_map_wrap {
		background-color: $off-white;
		position: relative;
		&:before {
			content:'';
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height: 300px;
			background-color: #fff;
		}
	}

	.store-locator--intro {}
	.store-locator--title {}
	.store-locator--description {}

	#wpsl-wrap {
		margin-top:60px;
		padding-top: 20px;
		border-top:2px solid $grey-divider;
		form {
			@extend .row;
			@extend .align-items-center;
			& > div {
			    padding-right: 27.5px;
			    padding-left: 27.5px;
			}
		}
	}

	.wpsl-search {
		background-color: transparent;
		padding:12px 0;
	}
	#wpsl-search-wrap {
		margin:20px 0 10px;

		@include media-breakpoint-down(md) {
			.wpsl-input,
			.wpsl-select-wrap,
			#wpsl-category {
				width:100%;
				margin-bottom: 15px!important;
				div,
				input, {
					width:100%;
				}
			}
			#wpsl-category,
			#wpsl-radius {
				width:100%;
			}
			#wpsl-category {
				margin-top: 15px!important;
			}
		}

		div {
			margin-right: 0;
		}
		.row {
			padding-right: 17.5px;
			padding-left: 17.5px;
			& > div {
				padding-left:10px;
				padding-right:10px;
				margin:0;
			}
		}
		input[type="text"],
		.wpsl-dropdown {
			height:46px;
			background-color: #f2f3f4;
			border:none;
			border-radius: 0;
			font-family: $pFont;
			font-size: 16px;
			line-height: 1.56;
			color:#7b7c7f;
			&:focus {
				outline:none;
			}
			&:hover {
				box-shadow: 0 0 5px rgba(0,0,0,.15);
			}
		}
		&::placeholder {

		}
	}

	.wpsl-dropdown .wpsl-selected-item, .wpsl-dropdown li, .wpsl-selected-item {
	    padding: 13px 12px!important;
	}

	.wpsl-selected-item {
		font-size: 16px;
		line-height: 1.56;
		color:#7b7c7f;
	}
	@include media-breakpoint-down(md) {
		.wpsl-search-btn-wrap {
			width:100%;
			display: block;
			text-align: center;
		}
	}

	#wpsl-search-btn {
		background-color: transparent;
		border:none;
		font-family: $pFont;
		background:none;
		box-shadow: none;
		background-color:$secondary;
		border-radius:0;
		color:white;
		padding: 11px 30px;
		text-transform: uppercase!important;
		transition:$transition;
		font-weight: 500;
    	letter-spacing: 1.1px;
		&:hover {
			background-color:$primary;
		}
		@include media-breakpoint-down(md) {
			margin-top: 20px!important;
			float:none!important;
		}
	}
	
	#wpsl-category {
		position: relative;
		label {
			position:absolute;
			text-transform: uppercase;
			width: 100%;
			top: -25px;
			font-size: 10px;
			color:$grey-med;
		}
	}

	#wpsl-result-list {
		margin-top: 40px;
		#wpsl-stores {
			height: auto!important;
			overflow: inherit;
			> ul {
				@extend .row;
				li {
					@extend .col-md-6;
					@extend .col-lg-4;
					border-bottom:none;
					.wpsl-store-location--wrap {
						height: 100%;
						border:1px solid $grey-divider;
						background-color: white;
						padding:30px 30px 10px 30px;
					}
				}
			}
		}
	}

	.wpsl-store-location {
		border-bottom:1px solid $grey-divider;
		padding-bottom: 10px;
	}

	.wpsl-store-location--title {
		margin:0 0 30px;
		font-weight: 500;
		line-height: 1.39;
		color:$grey-dark;
		display: block;
		text-align: center;
		font-family: $pFont;
		strong {
			font-weight: 500;
		}
	}

	.wpsl-store-location--address {
		margin-top:0px;
		padding-top:30px;
		border-top:1px solid $grey-divider;
	}

	.wpsl-store-location--address {
		font-family: $pFont;
		position: relative;
		padding-left:25px;
		line-height: 1.56;
		font-size: 16px;
		color:$grey-dark;
		i {
			position: absolute;
			left:0;
			top:29px;
			color:$primary;
			font-size: 20px;
		}
	}

	.wpsl-contact-details {
		font-family: $pFont;
		> span {
			position: relative;
			padding-left:25px;
			margin-top: 10px;
			line-height: 1.56;
			font-size: 16px;
			color:$grey-dark;
			i {
				position: absolute;
				left:0;
				top:3px;
				color:$primary;
				font-size: 14px;
			}
		}
		a {
			color:$primary;
			transition:$transition;
			&:hover {
				color:$secondary;
			}
		}
	}

	.wpsl-direction-wrap {
		margin-top:10px;
		@extend .row;
		> div {
			@extend .col-6;
		}
	}

	.wpsl-direction--distance {
		font-size: 11px;
		font-weight: 500;
		letter-spacing: 1.1px;
		color: #7d8386;
	}

	.wpsl-direction--url {
		font-size: 11px;
		text-align: right;
		.wpsl-directions {
			display: inline-block;
			color:$primary;
			transition: $transition;
			&:hover {
				color:$secondary;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}