.radio-input-wrap input[type="radio"] {
    opacity: 0;
    position: absolute;
    &:focus {
    	outline:none;
    }
}

.radio-input-wrap label {
    position: relative;
    display: inline-block;
    overflow:visible!important;
    cursor: pointer;
    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 26px!important;
    font-size:16px!important;
    margin-bottom:8px;
}

.radio-input-wrap label::before,
.radio-input-wrap label::after {
    position: absolute;
    content: "";
    
    /*Needed for the line-height to take effect*/
    display: inline-block;
}

/*Outer box of the fake checkbox*/
.radio-input-wrap label::before{
    height: 19px;
    width: 19px;
    background-color: #f2f3f4;
    border:1px solid $grey-divider;
    left: 0px;
    border-radius:50%;
    /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
     *to vertically center it.
     */
    top: 0px;
}

/*Checkmark of the fake checkbox*/
.radio-input-wrap label::after {
    width: 11px;
    height: 11px;
    background-color: $grey-dark;
    border-radius: 50%;
    top: 4px;
    left: 4px;
}

/*Hide the checkmark by default*/
.radio-input-wrap input[type="radio"] + label::after {
    content: none;
}

/*Unhide on the checked state*/
.radio-input-wrap input[type="radio"]:checked + label::after {
    content: "";
}

/*Adding focus styles on the outer-box of the fake checkbox*/
.radio-input-wrap input[type="radio"]:focus + label::before {
    border-color:$grey-med;
}
.gchoice_select_all, ul.gfield_checkbox li input[type=checkbox]:checked+label {
	font-weight: 400!important;
}

ul.gfield_checkbox > li {
	margin-bottom:15px!important;
}