.series--products-list-item {
	margin-bottom:70px;
	.series--products-list-item_inner {

	}
	
	.series--products-list-item_bg {
		background-color: white;
	}

	.series--products-list-item_content {
		padding:30px 0;
	}
	
	.series--products-list-item_title {
		color:$blue;
		margin:0 0 30px 0;
		text-align: center;
		@include media-breakpoint-down(md) {
			font-size: 26px;
		}
	}
	.series--products-list-item_short-description {
		margin-bottom:30px;
		color:$grey-dark;
		font-size: 16px;
		line-height: 1.56;
		text-align: center;
	}

	.component--swatch-name,
	.single-product--texture-title,
	.single-product--profiles-title {
		border-bottom:1px solid $grey-divider;
		margin-bottom:15px;
		padding-bottom:10px;
	}
	
	.single-product--textures {
		&.loading {
			&:before {
				display: none;
			}
			&:after {
				background-size:contain;
			}
			li {
				opacity: 0.4;
			}
		}
		li {
			display: block;
			opacity: 1;
			position: relative;
			padding-left:20px;
			&:before {
				content: "•";
				position: absolute;
				left: -6px;
    			top: -7px;
				font-size: 35px;
				color: #bbc2c6;
			}
			//padding-left:23px;
			//transition:opacity 0.3s ease;
			// DEPRECATED
			// &:hover {
			// 	border:none;
			// 	&:before {
			// 		border:none;
			// 		left:0;
			// 		top:50%;
			// 		transform:translateY(-50%);
			// 		width:14px;
			// 		height:14px;
			// 		border:1px solid $grey-dark;
			// 		background-color: transparent;
			// 		border-radius:50%;
			// 		transition:border-color 0.3s ease;
			// 	}
			// }
			// &:before {
			// 	content:'';
			// 	left:0;
			// 	top:50%;
			// 	transform:translateY(-50%);
			// 	width:14px;
			// 	height:14px;
			// 	border:1px solid $grey-divider;
			// 	background-color: transparent;
			// 	border-radius:50%;
			// }
			// &.active {
			// 	&:before {
			// 		border:1px solid $grey-dark;
			// 	}
			// 	&:after {
			// 		content:'';
			// 		border:none;
			// 		left:3px;
			// 		top:50%;
			// 		transform:translateY(-50%);
			// 		width:8px;
			// 		height:8px;
			// 		background-color:$grey-dark;
			// 		display: block;
			// 		border-radius: 50%;
			// 		position: absolute;
			// 	}
			// }
		}
	}

	.single-product--profiles-wrap {
		margin-top:25px;
		&.single-product--section-divider {
			border-top:none;
			margin-top: 0;
		}
		ul.single-product--profiles li {
			margin-bottom:4px;
		}
	}
	.single-product--profiles-title {}

	.series--product-link-wrap {
		margin-top:20px;
		text-align: center;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		border:1px solid $grey-divider;

		.series--products-list-item_content {
			padding:55px 55px 55px 20px;
		}

		.series--products-list-item_title,
		.series--products-list-item_short-description {
			text-align: left;
		}

		.series--product-link-wrap {
			
			text-align: left;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}