.single.single-post {
	@include topDivider('white');
	.single-post--content {
		color:$grey-dark;
		position:relative;
		p:first-of-type {
			font-size: 20px;
			font-weight: 300;
			line-height: 1.6;
		}
		padding-bottom:30px;
		margin-bottom:30px;
		border-bottom:1px solid $grey-divider;
	}	

	.single-post--content-container {
		padding:60px 0 110px;
		position: relative;
		@include topDivider('white');
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

		.single-post--content {
			padding-bottom:0px;
			margin-bottom:0px;
			border-bottom:none;
			position:relative;
			&:after {
				content:'';
				position: absolute;
				top:0;
				height:100%;
				width:1px;
				background-color:$grey-divider;
				right:-20%;
			}
		}
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

	}
}
