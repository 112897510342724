.block.posts-list {
	padding: 0;
	position: relative;
	padding-top:160px;
	margin-top:-120px;
	overflow: hidden;
	.posts-list--background {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background-image: url("../images/team_bg.svg");
		background-size: 100%;
		background-position: top;
		background-repeat: no-repeat;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
	}

	.posts-list--title {
		font-family: $pFont;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.48;
		color:$grey-dark;
		text-align: center;
		margin:0;
	}
	#posts {
		width:100%;
		height:110px;
	}
	.posts-list--filters-container {
		position: relative;
	}
	
	.posts-list--select-wrap {
		background-color: #f2f3f4;
	}

	.posts-list--filter-form {
		padding:0;
		margin:0;
		.posts-list--select-field {
			width:100%;
			max-width:231px;
			&:first-child {
				margin-right:20px;
			}
		}
	}

	.posts-list--results_info {
		font-size: 11px;
		font-weight: 500;
		letter-spacing: 1.1px;
		color: #7d8386;
		text-transform: uppercase;
	}

	.posts-list--reset-filters {
		color: #7d8386;
		border: 1px solid #7d8386;
		padding:6px 4px 6px 7px;
		text-decoration: none;
		margin-top: 10px;
		display: inline-block;
		line-height: 1em;
		transition:$transition;
		&:hover {
			background-color: #7d8386;
			color:white;
		}
	}

	.posts-list--posts-list-results {
		margin-top: 50px;
		&:not(.hidden) {
			padding-bottom: 90px;
			position: relative;
			&:before {
				content:'';
				position: absolute;
				width:100%;
				height:calc(100% - 160px);
				background-color:$off-white;
				top: 160px;
				left:0;
			}
		}
	}

	.load-more-button-container {
		text-align: center;
	}

	.dynamic-content {
		position:relative;
		&.loading {
			&:after {
				content:'';
				position:absolute;
				top:0;
				left:0;
				top:0;
				left:0;
				background-color:rgba(255,255,255,0.6);
				background-image:url('../images/loading.gif');
			    height: 100%;
			    width: 100%;
			    background-repeat: no-repeat;
			    background-position: center 100px;
			}
		}
	}

	.no-posts {
		//margin-bottom: 250px;
	}

	.posts-list--item {
		.post-item {
			margin:0 0 60px 0;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.posts-list--results_info {
			text-align: right;
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
