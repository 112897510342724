.block.basic-content {
	--bg-color: #FFFFFF;
	--font-color: $grey-dark;
	
	padding: 3rem 0;
	z-index: 0;
	background-color: var(--bg-color);
	overflow: hidden;

	&:first-child {
		padding: 0;
	}

	.basic-content--content-container {
		margin-bottom:30px;
		position: relative;
		z-index: 2;
	}

	&.grey {
		--bg-color: $off-white;
	}

	&.top-white-divider {
		position: relative;
		@include topDivider('white');
		margin-top: 0;
	}

	&.top-grey-divider {
		position: relative;
		@include topDivider('grey2');
		margin-top: 0;
	}
	
	h1,h2,h3,h4,h5,h6 {
		font-weight: 500;
		margin-top: 0;
	}
	h1,h2,h3,h4,h5,h6,p,ul,ol {
		font-family: $pFont;
		color: var(--font-color);
	}

	p {
		color: var(--font-color);
	}

	a {
		color:$primary;
		transition:$transition;
		&:hover {
			color:$secondary;
		}
	}
	iframe {
		width:100%;
	}

	&.dark-woodgrain {
		--font-color: $white;

		background: url('../images/woodgrain_dark_grey_top.svg');
		background-position-y: -125px;

		&.grey-woodgrain-divider-top {
			background: transparent;
			position: relative;

			&:before {
				--topOffset: 200px;

				display: block;
				content: '';
				background-image: url('../images/woodgrain_dark_grey_top.svg');
				position: absolute;
				width: 100%;
				height: calc(100% + var(--topOffset));
				background-size: cover;
				background-position: top left;
				background-repeat: no-repeat;
				left: 50%;
				top: calc(var(--topOffset) * -1);
				transform: translateX(-50%);
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
			padding-top: 10rem;
			padding-bottom: 10rem;

		.basic-content--content-container {
			margin-bottom: 0px;

		}

		&.dark-woodgrain.grey-woodgrain-divider-top:before {
			--topOffset: 150px;
		}
	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		padding: 0;
		overflow: visible;

		.basic-content--content {
			padding-bottom: 0;
		}
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
		&.dark-woodgrain.grey-woodgrain-divider-top:before {
			--topOffset: 175px;
		}
	}
}