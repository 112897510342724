.cat-list {
	@include list();
	li {
		&.has-link {
			padding:0;
			a {
				display: block;
				padding:4px 6px;
				text-decoration: none;
				color:$grey-med;
				transition:$transition;
				&:hover {
					color:white;
					background-color:$grey-divider;
				}
			}
		}
		display: inline-block;
		border:1px solid $grey-divider;
		font-size: 9.5px;
		font-weight: 500;
		letter-spacing: 0.9px;
		color: $grey-med;
		text-transform: uppercase;
		padding:4px 6px;
		line-height: 1em;
		margin-bottom:0px;
		margin-right: 10px;
	}
}