.page-template-template-designer {

	.space-designer--selection-info_container {
		padding-top:50px;
		padding-bottom:50px;
		@include media-breakpoint-down(sm) {
			order:1;
		}
	}
	
	.space-designer--wrap {
		position: relative;
		&.loading {
			&:after {
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				height: 100%;
				background-color:rgba(255,255,255,0.8);
				content:'';
				background-image:url(../images/loading.gif);
				background-position: center;
				background-repeat:no-repeat;
			}
			.space-designer--next-wrap a.btn {
				display: none;
			}
		}
	}

	.space-designer--wrap {
		background-color: $off-white;
	}
	
	.space-designer--selection-info {
		margin-bottom:20px;
		border-bottom:1px solid $grey-divider;
		&.step-5 {
			border-bottom: 0;
		}
	}

	.space-designer--item-info {
		padding-bottom:0px;
		margin-bottom:20px;
	}

	.space-designer--item-info_top {
		@extend .row;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom:10px;
		position: relative;
	}
	.space-designer--category {
		font-family:$pFont;
		font-size: 11px;
		font-weight: 500;		
		letter-spacing: 1.1px;
		color:$grey-dark;
		margin:0;
		padding-left:27.5px;
		padding-right:27.5px;
	}
	.space-designer--change {
		font-size: 11px;
		text-align: right;
		color:#7b7c7f;
		padding-left:27.5px;
		padding-right:27.5px;
		transition:$transition;
		&:hover {
			color:$secondary;
		}
	}
	.space-designer--category_selection {
		font-family: $pFont;
		font-weight: 500;
		margin:0 0 10px;
		position: relative;
	}


	.space-designer--step_intro-text {
		font-size: 18px;
		font-weight: 500;
		line-height: 1.28;
		color:$grey-dark;
		margin-bottom:30px;
	}

	h4.space-designer--step_intro-text {
		font-size: 23px;
		font-weight: 500;
		font-family: $pFont;
		line-height: 1.39;
	}

	.space-designer--step_title {
		font-size: 31px;
		font-weight: 500;
		line-height: 1.32;
		color:$grey-dark;
		font-family: $pFont;
	}

	.space-designer--selection-options {
		margin-bottom:30px;
		padding-bottom:30px;
		border-bottom:1px solid $grey-divider;
	}

	.radio-input-wrap {
		label {
			color:$grey-dark;
			font-family: $pFont;
			font-size: 16px;
			line-height: 1.19;
		}
	}

	.space-designer--image-col {
		position: relative;
		padding-bottom:56.25%;
	}
	.space-designer--image-wrap {
		position: absolute;
		top: 0;
		left:0;
		width:100%;
		height: 100%;
		&.loading {
			&:after {
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				height: 100%;
				background-color:rgba(255,255,255,0.8);
				content:'';
				background-image:url(../images/loading.gif);
				background-position: center;
				background-repeat:no-repeat;
			}
		}
	}
	.space-designer--dynamic-image {
		position: absolute;
		top: 0;
		left:0;
		width:100%;
		height: 100%;
		background-size:cover;
		background-position:center;
	}
	
	.space-designer--selection-title {
		margin:0;
		font-family: $pFont;
		font-size: 11px;
		font-weight: 500;
		letter-spacing: 1.1px;
		padding-right: 5px;
		margin:0 0 0;
	}

	.space-designer--selection {
		font-size: 16px;
		font-weight: 500;
		line-height: 1.56;		
	}

	.space-designer--final-product-title {
		font-weight: 500;
		line-height: 1.48;
		font-family: $pFont;
		border-top:1px solid $grey-divider;
		padding-top:30px;
	}


	.space-designer--prev-wrap {
		float: left;
		a {
			padding-left:24px;
			padding-right:6.5px;
			&:hover {
				&:after {
					transform:translate(-4px,-50%) rotate(180deg);
				}
			}
			&:after {
				transform:translateY(-50%) rotate(180deg);
				right:inherit;
				left:0;
			}
		}
	}

	.space-designer--next-wrap {
		float:right;
		.btn.disabled {
			display: none;
		}
	}

	.designer--selections-mobile {
		display: block;
		.space-designer--selection-info {
			margin-top: 20px;
			border-bottom: none;
			margin-bottom: 	40px;
		}
		.space-designer--step_intro-text {
			margin-top:37px;
			font-size:26px;
			text-align: center;
		}
	}
	.designer--selections-desktop {
		display: none;
	}

	.space-designer--final-list {
		td {
			min-width:105px;
			vertical-align: top;
			padding-bottom: 13px;
			span {
				display: block;
				margin-top: -3px;
				line-height: 1em;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.space-designer--image-col {
			min-height: 75vh;
			padding-bottom:0;
		}

		.designer--selections-mobile {
			display: none;
		}
		.designer--selections-desktop {
			display: block;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}