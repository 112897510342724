.product-template-template-series {
	.series--why-woodtone {
		padding:50px 0;
		&.series--products-hide-title {
			padding-bottom:75px;
		}
	}

	.series--why-woodtone_title {
		font-family: $pFont;
		text-align: center;
		font-weight: 500;
		line-height: 1.89;
		position:relative;
		display: table;
		margin:0 auto 65px;
		&:after {
			position: absolute;
			content:'';
			background-color:$grey-divider;
			width:100%;
			height:1px;
			bottom:-30px;
			left:0;			
		}
	}

	.why-woodtone-items-slider {
		.slick-arrow {
			color:$grey-light;
			font-size:35px;
			transition:$transition;
			&:hover {
				color:$primary;
			}
			&.slick-prev {
				display: block;
				padding: 0;
				text-align: center;
				left: -50px;
			}
			&.slick-next {
				display: block;
			    padding: 0;
			    text-align: center;
			    right: -50px;
			}
		}
	}

	.why-woodtone-items-dots {
		margin-top:40px;
	}

	.series--why-woodtone-item {
		font-size: 22px;
		font-weight: 300;
		font-style: normal;
		line-height: 1.48;
		text-align: center;
		color: $grey-dark;
	}

	.series--product-title-container {
		position: relative;
	}

	.series--products-title {
		text-align: center;
		font-family: $pFont;
		margin:50px 0;
		font-weight: 500;
		line-height: 1.48;
	}

	.series--products {
		background-color: $off-white;
		background-image: url(../images/wood-grain-greywhite_tall.svg);
		background-size: cover;
		background-position: 50%;
		padding-top:60px;
		&.series--products-hide-title {
			padding-top:0;
		}

		.contained-container {
			background-color: white;
			padding:10px;
		}
	}

	.series--product-hotspots_container {
		margin-bottom:80px;
	}

	.series--product-hotspots {
		position: relative;
		img {
			width:100%;
			max-width:none;
			height:auto;
		}
		.hot-spot {
			position: absolute;
			display: block;
			width:48px;
			height:48px;
			line-height: 44px;
			text-align: center;
			background-color: white;
			border-radius: 50%;
			color:$grey-dark;
			font-weight: bold;
			font-size: 27px;
			transform:translate(-24px,-24px);
			cursor: pointer;
			transition:background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
			border:2px solid $grey-dark;
			&:before,
			&:after {
				transition:opacity 0.3s ease, height 0.3s ease, width 0.3s ease;
				height: 48px;
				width:48px;
				border-radius: 50%;
				content:'';
				position: absolute;
				left:50%;
				top:50%;
				transform:translate(-50%,-50%);
				border:1.8px solid white;
				opacity:0;
			}
			&:not(.active) {
				&:before {
					height: 60px;
					width:60px;
					opacity: 0.2;
				}
				&:hover {
					//background-color:$grey-light;
					&:before {
						width:48px;
						height:48px;
						opacity: 0;
					}
				}
			}
			&.active {
				opacity: 1;
				background-color:$secondary;
				color:#fff;
				border-color:white;
			    &:before {
			      animation: 3s pulse2 infinite linear;
			      //animation-delay: 1s;
			    }
			    
			    &::after {
			      animation: 3s pulse infinite linear;
			      //animation-delay: 1s;
			    }
			}
		}
	}

	.series--hot-spot-product-list {
		text-align: center;
		list-style: none;
		counter-reset: item;
		padding:0;
		li.series--hot-spot-product_accordion {
			counter-increment: item;
			position: relative;
			border-bottom:1px solid $grey-light;
			margin: 15px 0;
			padding: 15px 0;
			&:last-child {
				border-bottom:none;
			}
			&.active {
				@include media-breakpoint-down(sm) {
					max-height:9999px;
					h4 {
						margin: 15px 0;
						padding: 15px 0;
					}
				}
				h4 {
					color:$grey-dark;
					&:before {
						color:$secondary;
					}
				}
			}
			@include media-breakpoint-down(sm) {
				overflow:hidden;
				padding:0;
				margin:0;
				border-bottom: none;
				h4 {
					max-height:0;
				}
			}
			h4 {
				margin:0;
				font-weight: 500;
				line-height: 1.39;
				font-family: $pFont;
				color:$grey-light;
				position: relative;
				transition:$transition;
				cursor: pointer;
				&:hover {
					color:$primary;
					&:before {
						color:$secondary;
					}
				}
				&:before {
					content: counter(item);
					display: inline-block;
					position: absolute;
					color:$grey-light;
					left:-30px;
					top:0;
					font-family: $pFont;
					font-size: 20px;
					font-weight: bold;
					line-height: 1.6;
					transition:color 0.3s ease;
				}
			}
		}
	}

	.series--hot-spot-product_content {
		font-size: 16px;
		line-height: 1.56;
		color:$grey-dark;
		margin:15px auto 0;
		display: none;
		max-width: 400px;
	}

	.series--hot-spot-product_link-wrap {
		margin:15px 0 10px;
	}

	.series--product-dynamic-image-wrap {
		position: relative;
		@include loading();
		padding-bottom:56.25%;
	}
	.series--product-dynamic-image {
		position:absolute;
		top:0;
		left:0;
		height:100%;
		width: 100%;
		background: {
			size:cover;
			position:center;
			repeat:no-repeat;
		}
	}
	
	.series--mobile-dynamic-image {
		display: block;
		padding-bottom: 100%;
    	margin-bottom: 40px;
	}
	.series--desktop-dynamic-image {
		display: none;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.series--why-woodtone {
			position: relative;
			padding:60px 0 60px;
			@include topDivider('white');
			@include bottomDivider('white');
			&.series--products-hide-title {
				padding-bottom:125px;
			}
		}

		.series--hot-spot-product_content {
			margin:15px 0 0;
			max-width: none;
		}

		.series--product-dynamic-image-wrap {
			padding-bottom: 0;
		}

		.series--why-woodtone-item {
			font-size: 31px;
		}

		.series--hot-spot-product-list {
			text-align: left;
		}

		.series--mobile-dynamic-image {
			display: none;
		}
		.series--desktop-dynamic-image {
			display: block;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}


// outer circle
@keyframes pulse {
  0% {
    transform: translate(-50%,-50%) scale(1);
    opacity: .5;
  }
  100% {
    transform: translate(-50%,-50%) scale(1.8);
    opacity: 0;
  }
}

// inner circle
@keyframes pulse2 {
  0% {
    transform: translate(-50%,-50%) scale(1);
    opacity: .7;
  }
  100% {
    transform: translate(-50%,-50%) scale(1.1);
    opacity: 0;
  }
}