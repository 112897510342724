.block.products {

	position: relative;
	@include topDivider('white');

	.products--title {
		text-align: center;
		font-family: $pFont;
		font-weight: 500;
		margin: 0;
	}

	.products-list--item {
		.post-item {
			margin-top:40px;
		}

		.post-item--product-parent-title {
			text-transform: uppercase;
			margin:25px 0 0;
		}
		.post-item--title {
			margin:10px 0;
		}
	}

	.products--results_info {
		font-size: 11px;
		font-weight: 500;
		letter-spacing: 1.1px;
		color: #7d8386;
		text-transform: uppercase;
	}

	.products--filters-container {
		margin-top:60px;
		position: relative;
		padding-top:40px;
		&:before {
			content:'';
			background-color:$grey-divider;
			height: 1px;
			width:calc(100% - 55px;);
			position:absolute;
			top:0;
			left:50%;
			transform:translateX(-50%);
		}
	}


	.products--filter-form {
		margin:0;
		padding:0;
		.products--select-field {
			width:100%;
			max-width:231px;
			&:first-child {
				margin-right:20px;
			}
		}
	}

	.products--results_info {
		font-size: 11px;
		font-weight: 500;
		letter-spacing: 1.1px;
		color: #7d8386;
		text-transform: uppercase;
	}

	.products--select-wrap {
		background-color: #f2f3f4;
	}

	.products--reset-filters {
		color: #7d8386;
		border: 1px solid #7d8386;
		padding:6px 4px 6px 7px;
		text-decoration: none;
		margin-top: 10px;
		display: inline-block;
		line-height: 1em;
		transition:$transition;
		&:hover {
			background-color: #7d8386;
			color:white;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {


		.products--results_info {
			text-align: right;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}