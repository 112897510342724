.slick-prev,
.slick-next {
	display: block;
	position: absolute;
	transform:translateY(-50%);
	top: 50%;
	background-color:transparent;
	border:none;
	color:white;
	width:39px;
	cursor: pointer;
	transition:color 0.3s ease;
	z-index: 1;
	font-size:30px;
	&:hover {
		color:$primary;
	}
	&:focus {
		outline: none;
	}
	&.slick-disabled {
		background-color:darken($grey-light, 15%);
	}
}

.slick-prev {
	left:10px;
}
.slick-next {
	right:10px;
}

.slick-dots {
	@include list();
	text-align: center;
	li {
		display: inline-block;
		text-align: center;
		margin-right: 7px;
		&:last-child {
			margin-right: 0;
		}
		&.slick-active {
			button {
				background-color:$primary;
			}
		}
		button {
			transition:$transition;
			background-color: transparent;
			border:none;
			outline:none;
			border-radius:50%;
			display: block;
			width:16px;
			height:16px;
			line-height: 1em;
			font-size:0;
			background-color:$grey-light;
			cursor: pointer;
			&:focus {
				outline:none;
			}
			&:hover {
				background-color:darken($grey-light, 15%);
			}
		}
	}
}

@mixin slick-round-buttons() {
	.slick-arrow {
		text-indent: -99999px;
		background: $white;
		height: 35px;
		width: 35px;
		border-radius: 100%;
		padding: 0;

		&:after {
			--flip-icon: 1;

			@include iconFont;

			text-indent: 0;
			content: "\";
			position:absolute;
			font-size: 20px;
			color: $paleblue;
			top: 50%;
			left: 0;
			right: 0;
			margin-left: 0;
			margin-right: 0;
			text-align: center;
			transform: scaleX(var(--flip-icon)) translateY(-50%);
		}

		&.slick-prev:after {
			--flip-icon: -1;
		}

		&.slick-next:after {
			//
		}
	}
}