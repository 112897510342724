.page-template-template-landing {
    color: $grey-dark;
    
    .LandingPage__section {
        
        @include media-breakpoint-up(xl) {
            .wp-blockjs-dynamic-accordion-content {
                display: block!important;
            }
        }
    }

	.single-post--related-posts {
		background: $off-white;
		margin-top: 6.25rem;
	}

	.col-md-6[data-type="image"] {
		align-self: stretch;
		justify-content: flex-start;

		&:first-child {
			justify-content: flex-end;
		}
	}

	.col-md-6[data-type="wysiwyg"] {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

    .block.basic-content {
        img {
			min-width: 100%;
			height: 100%;
			object-fit: cover;
		}
    }
    /* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		.block.basic-content {
            padding-top: 3rem;
			padding-bottom: 3rem;

			.row {
				flex-wrap: wrap;

				.col-md-6 {
					flex: 100%;
					max-width: none;
				}
			}
        }

		.col-md-6[data-type="wysiwyg"] {
			padding-top: 5rem;
			padding-bottom: 5rem;
		}

		.block.basic-content.dark-woodgrain.grey-woodgrain-divider-top::before {
			--topOffset: 210px;
		}

		.col-md-6[data-type="image"] {
			justify-content: flex-start;
			
			.basic-content--content,
			.basic-content--content-container {
				justify-content: flex-start;
			}

			&:last-child {
				padding-bottom: 5rem;
			}
	
			&:first-child {
				justify-content: flex-end;
				padding-top: 5rem;

				.basic-content--content,
				.basic-content--content-container {
					justify-content: flex-end;
				}
			}
		}

		.block.basic-content {
			img {
				width: auto;
				height: 100%;
				object-fit: cover;
				max-width: none;
			}
		}
	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		.block.basic-content {
			padding-top: 3rem;
			padding-bottom: 0;
		}
		
		.block.basic-content {
			.row {
				flex-wrap: wrap;
				
				.col-md-6 {
					flex: 0 0 50%;
					max-width: 50%;
				}

				.col-md-6,
				.basic-content--content-container,
				.basic-content--content-container.is_image .basic-content--content {
					display: flex;
					align-items: stretch;
				}

				.basic-content--content-container.is_image .basic-content--content {
					align-items: end;
					padding-bottom: 0;
				}
			}
		}

		.block.basic-content.dark-woodgrain.grey-woodgrain-divider-top::before {
			--topOffset: 150px;
		}

		.col-md-6[data-type="image"]:first-child {
			padding-top: 0;
		}

		.col-md-6[data-type="image"]:last-child {
			padding-bottom: 0;
		}
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
		
	}

	@media screen and (min-width: 1500px) {
		.block.basic-content {
			padding-top: 5rem;
		}
	}
}