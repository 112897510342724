.block.featured-projects {
	padding:75px 0;
	position: relative;
	z-index: 2;

	.component-filter--filters-container {
		margin-top: 0;
		padding-top: 0;
	}

	.featured-projects--mobile-more-trigger {
		position:absolute;
		bottom:0;
		left:50%;
		transform:translateX(-50%);
		z-index: 1;
		a {
			font-size: 20px;
			&:after {
				transform: rotate(90deg);
				top: 7px;
			}
			&:hover {
				&:after {
					transform: rotate(90deg);
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.featured-projects--row {
			max-height:1050px;
			overflow:hidden;
			position:relative;
			transition:max-height 0.3s ease;
			&.reveal-more-projects {
				max-height:9999999px;
				&:after {
					display: none;
				}
			}
			&:after {
				content:'';
				position:absolute;
				bottom:0;
				width:100%;
				background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
				height: 200px;
				left:0;
			}
		}
	}

	&.top-white-divider {
		position: relative;
		padding-top:60px;
		@include topDivider('white');
		margin-top:0;
	}

	.featured-projects--background {
		position:absolute;
		width:100%;
		height:calc(100% + 125px);
		background: {
			size:100%;
			position:bottom center;
			repeat:repeat;
		}
		left: 50%;
	    bottom: -125px;
	    transform: translateX(-50%);
	}

	.featured-projects--title-container {
		margin-bottom: 45px;
	}
	.featured-projects--title {
		color:$slate;
		margin:0 0 0px 0;
		text-align: center;
	}
	.featured-projects--link-wrap {
		margin-top: 20px;
		text-align: center;
	}

	.ajax-row {
		position: relative;
		&.loading {
			&:after {
				content:'';
				position:absolute;
				top:0;
				left:0;
				top:0;
				left:0;
				background-color:rgba(255,255,255,0.6);
				background-image:url('../images/loading.gif');
			    height: 100%;
			    width: 100%;
			    background-repeat: no-repeat;
			    background-position: center 100px;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		padding:138px 0;
	
		.featured-projects--background {
			background-image: url('../images/woodgrain_bottom.svg');
		}

		.featured-projects--title {
			text-align: left;
		}
		.featured-projects--link-wrap {
			text-align: right;
			margin-bottom: 35px;
		}

		.featured-projects--title-container {
			margin-bottom: 75px;
		}

		.featured-projects--mobile-more-trigger {
			display: none;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}