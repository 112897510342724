.site-header {
	position: fixed;
	z-index: 100;
	top: 0;
	width: 100%;
	transition:transform 0.3s ease;
	.admin-bar & {
		top: 32px;
	}
	.site-info {
		transition: $transition;
		position:relative;
		z-index: 1;
		background-color:white;
		> .container  > .row {
			padding:0 27.5px;
			height: $header-mobile-height;
		}
		> .container-fluid  > .row {
			padding:0 27.5px;
			height: $header-mobile-height;
		}
		.brand {
			width:100%;
			max-width: 142px;
			img {
				width:100%;
				display:block;
	
			}
		}



	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {



	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
		.menu-trigger-container {
			display: none;
		}

		.site-info {
			.shrink & {
				transform:translateY(-30px);
			}
			> .container > .row {
				height: $header-desktop-height;
				transition:$transition;
			}
			> .container-fluid > .row {
				height: $header-desktop-height;
				transition:$transition;
			}
			.brand {
				width:100%;
				max-width: 190px;
				transition:$transition;
				img {

				}
			}
		}
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
		

	}
}