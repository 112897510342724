	@include bodyTypography();

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family:$hFont;
		line-height: 1em;
		font-weight: 600;
		&.grey-sub {
			font-family: $pFont;
			color:$grey-med;
			letter-spacing: 1.1px;
			margin: 0 0 10px 0;
			font-weight: 500;
		}
	}
		h1 {
			font-size: 41px;
		}
		h2{
			font-size:32px;
		}
		h4 {
			font-size: 23px;
		}
		h5 {
			font-size: 18px;
		}

	p {
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom:0;
		}
		a {
			text-decoration: none;
			
			transition:$transition;
			&:hover {
				color:$grey-med;
			}
		}
	}

	strong {
		font-weight: 700;
	}
	
	sup.small-trademark {
		font-size: 0.45em;
		top: -1em;
	}
	.small-heading {
		font-size: 11px;
		font-weight: 500;
		letter-spacing: 1.1px;
		text-transform: uppercase;
		color: $grey-dark;
		&.accordion--title {
			position:relative;
			cursor: pointer;
			&:after {
				content:'\';
				@include iconFont();
				position: absolute;
				right:0;
				top:5px;
				transform:rotate(90deg);
				color:$grey-divider;
				font-size:16px;
				transition:transform 0.3s ease;
			}
			&.active {
				&:after {
					transform:rotate(-90deg);
				}
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.accordion--content {
			display: none;
		}
	}

	@include media-breakpoint-up(md) {
		.js-dynamic-accordion-content {
			display: block!important;
		}
	}

/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		h1 {
			font-size:57px;
		}
		h2{
			font-size:48px;
		}
		h3 {
			font-size:31px;
		}
		h4 {
			font-size: 23px;
		}
		h5 {
			font-size:18px;
		}
		h6 {
			font-size:11px;
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}