.btn {
	display: inline-block;
	text-decoration: none;
	text-align: center;
	position: relative;
	outline:none;
	border:none;
	appearance:none;
	text-transform: uppercase;
	font-weight: bold;
	cursor: pointer;
	font-weight: 500;
	font-family: $pFont;
	transition:background-color 0.3s ease, color 0.3s ease;

	&.primary {
		background-color:$primary;
	}
	&.secondary {
		background-color:$secondary;
	}
	&.slate {
		background-color:$slate;
	}
	&.primary,
	&.secondary,
	&.slate {
		padding:12px 25px;
		border-radius:5px;
		font-size: 14px;
		letter-spacing: 2px;
		color:white;
		&:hover {
			background-color:$navy;
			&:before {
				border-color: transparent transparent $navy transparent;
			}
		}
		&.small {
			font-size: 11px;
			font-weight: 300;
			letter-spacing: 1.1px;
			padding:14px 43px;
		}
		&.extra-small {
			font-size: 11px;
			font-weight: 300;
			letter-spacing: 1.1px;
			padding:8px 20px;
		}
	}
	&.btn-arrow {
		padding:6.5px 35px 6.5px 15px;
		&:after {
			@include iconFont();
			font-size: 13px;
			content: "\";
			right:12.5px;
			top:48%;
			transform:translateY(-50%);
			position:absolute;
			color:$secondary;
			transition:all 0.3s ease;
		}
	}

	&.simple {
		color:$primary;
		padding-left:0;
		padding-top: 0;
		padding-bottom: 0;
		text-transform: none;
		font-weight: 500;
		font-family: $pFont;
		&:hover {
			color:$secondary;
			&:after {
				transform:translate(4px, -50%);
			}
		}
	}
	&.white {
		&.simple {
			color:white;
		}
		&.btn-arrow {
			&:after {
				color:white;
			}
		}
	}

	&.btn-transparent {
		background-color: transparent;
		color:$primary;
		padding-top: 0;
		padding-bottom:0;
		&:hover {
			color:$navy;
		}
	}
	
	&.btn-primary-outline {
		border:2px solid $primary;
		color:$primary;
		&:hover {
			color:white;
			background-color:$primary;
		}
	}

	&.no-arrow {
		padding:6.5px 15px 6.5px 15px;
		color:$primary;
		&:hover {
			color:white;
		}
	}

	&.btn-white {
		color:white;
	}

	&.btn-big-mobile {
		width: 100%;
		background-color:$primary;
		color:white;
	}

	&.disabled {
		color:$grey-divider;
		cursor:default;
		&:hover {
			color:$grey-divider;
			&:after {
				transform:translate(0px,-50%)
			}
		}
		&:after {
			color:$grey-divider;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}

}


