.block.interior-banner {
	background: {
		color: $off-white;
		image:url('../images/woodgrain_greywhite.svg');
		size: cover;
		position: center;
	}
	padding:0;

	.interior-banner--content {
		padding:50px 0 80px;
		@include media-breakpoint-down(sm) {
			position: relative;
			padding-top:30px;
			@include topDivider('grey');
			&:before {
				top: -148px;
			}
		}
	}

	.interior-banner--title {
		color:$slate;
		margin:0 0 20px 0;
	}

	.interior-banner--description {
		font-size: 16px;
		line-height: 1.56;
		color: $grey-dark;
		position: relative;
		z-index: 1;
	}

	.interior-banner--image-wrap {
		position: relative;

	}

	.interior-banner--image {
		width:calc(100% + 55px);
		margin-left:-27.5px;
		padding-bottom:50%;
		background: {
			size:cover;
			position: center;
		}
	}

	.interior-banner-item--categories {
		margin-top: 30px;
	}

	.interior-banner--series-banner-products-link-wrap {
		margin-top:20px;
		position: relative;
		z-index: 3;
	}
		.interior-banner--series-banner-products-link {
			&:after {
				transform:translateY(-50%) rotate(90deg);
			}
		}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		overflow: hidden;

		.interior-banner--title {
			font-size: 34px;
		}

		.interior-banner--image-wrap {
			min-height: 450px;
		}

		.interior-banner--content {
			padding:100px 0;
		}

		.interior-banner--image {
			position:absolute;
			width:65vw;
			left:27.5px;
			height:100%;
			top: 0;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {


		
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
		.interior-banner--image-wrap {
			min-height: 575px;
		}

		.interior-banner--title {
			font-size: 57px;
		}

		.interior-banner--content { 
			padding:125px 0;
		}

	}
}