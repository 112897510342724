.post-item {
	margin:40px 0 10px;
	.post-item--thumb {
		padding-bottom: 70%;
		display: block;
		background: {
			size:cover;
			position:center;
			repeat:no-repeat;
		}
	}

	.post-item--title {
		margin:25px 0 20px;
		font-family: $pFont;
		font-weight: 500;
		line-height: 1.39;
		letter-spacing: normal;
		color: $grey-dark;
		a {
			transition:$transition;
			color: $grey-dark;
			text-decoration: none;
			&:hover {
				color:$secondary;
			}
		}
	}

	.post-item--link-wrap {
		margin-top: 20px;
	}

	.post-item--date {
		font-size: 11px;
		font-weight: 500;
		letter-spacing: 1.1px;
		color: #7d8386;
		text-align: right;
	}
	
	.post-item--short-description {
	  font-size: 16px;
	  line-height: 1.56;
	  color:$grey-dark;
	  margin-bottom:20px;
	}
	.post-item--title-date {
		margin-top:20px;
		margin-bottom:15px;
	}
	&.post {
		.post-item--title {
			margin:0;
		}
		.post-item--link-wrap {
			margin-top:10px;
		}
	}

	.post-item--related-project-categories {
		text-transform: uppercase;
		font-family: $pFont;
		color:$grey-med;
		letter-spacing: 1.1px;
		margin: 0 0 10px 0;
		font-weight: 500;
		margin:0 0 10px 0;
		font-size:11px;
		line-height: 1em;
	}

	&.related_project {
		.post-item--thumb {
			margin-bottom:20px;
		}
		.post-item--title {
			margin:0 0 8px 0;
		}
		.post-item--description {
			font-size: 16px;
			line-height: 1.56;
			color:$grey-dark;
		}
	
	}


	.post-item--accordion-content {
		width:100%;
		max-width:500px;
		font-size: 13px;
		display: none;
	}

	.post-item--accordion-title {
		position:relative;
		cursor: pointer;
		&:after {
			content:'\';
			@include iconFont();
			position: absolute;
			right:0;
			top:5px;
			transform:rotate(90deg);
			color:$grey-divider;
			font-size:16px;
			transition:transform 0.3s ease;
		}
		&.active {
			&:after {
				transform:rotate(-90deg);
			}
		}
	}
	.post-item--accordion {
		padding-top:10px;
		margin-top: 10px;
		border-top:1px solid $grey-divider;
		border-bottom:1px solid $grey-divider;
		padding-bottom:10px;
		margin-bottom:10px;
	}

	ul.post-item--resources-list {
		@include list();
		margin:20px 0;
		li {
			line-height: 1em;
			break-inside: avoid;
			margin-bottom:5px;
			padding-left:20px;
			position: relative;
			i {
				position: absolute;
				left:0;
				top:1px;
				margin-right: 5px;
				vertical-align: -2px
			}
			a {
				color:$slate;
				transition:$transition;
				&:hover {
					color:$secondary;
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		margin:60px 0;

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
		ul.post-item--resources-list {
			columns:2;	
		}

	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}