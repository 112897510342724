.component-filter--filters-container {
	margin-top:60px;
	position: relative;
	padding-top:40px;
	&.has-title {
		&:before {
			content:'';
			background-color:$grey-divider;
			height: 1px;
			width:calc(100% - 55px;);
			position:absolute;
			top:0;
			left:50%;
			transform:translateX(-50%);
		}
	}
}


.component-filter--filter-form {
	padding:0;
	margin:0;
	.component-filter--select-field {
		width:100%;
		max-width:231px;
		&:first-child {
			margin-right:20px;
		}
	}
}

.component-filter--results_info {
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 1.1px;
	color: #7d8386;
	text-transform: uppercase;
}

.component-filter--reset-filters {
	color: #7d8386;
	border: 1px solid #7d8386;
	padding:6px 4px 6px 7px;
	text-decoration: none;
	margin-top: 10px;
	display: inline-block;
	line-height: 1em;
	transition:$transition;
	&:hover {
		background-color: #7d8386;
		color:white;
	}
}

.component-filter--select-wrap {
	background-color: #f2f3f4;
}

.component-filter--component-filter-results {
	margin-top: 90px;
	&:not(.hidden) {
		padding-bottom: 90px;
		position: relative;
		&:before {
			content:'';
			position: absolute;
			width:100%;
			height:100%;
			background-color:$off-white;
			top: 70px;
			left:0;
		}
	}
}