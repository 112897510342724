.gform_wrapper {
	.gfield {
		position: relative;
		margin-bottom:15px;
		.ginput_container {
			margin-top: 0!important;
		}

		.gfield_radio {
			label {
				font-size: 14px;
				margin-top: 7px;
			}
		}

		.select-wrap,
		.ginput_container_select {
			position:relative;
			display: block;
			background-color: white;
			&.component-filter--select-wrap {
				background-color: #f2f3f4;
			}
			select {
				display: block;
				width:100%;
			}
			&:before {
				@include iconFont();
				content: "\";
				position:absolute;
				right:20px;
				top:50%;
				transform:translateY(-50%) rotate(90deg);
				color:#7b7c7f;
			}
		}
		.address_country {
			select {
				background-color: #f2f3f4;
				appearance:none;
				height: 46px;
			}
		}
		.ginput_container_select {
			background-color: #f2f3f4;
			select {
				padding-left:10px;
				padding-right: 10px;
			}
		}

		label.gfield_label {
			font-weight: normal;
			font-size: 11px;
			font-weight: 500;
			letter-spacing: 1.1px;
			transition: $transition;
			font-family: $pFont;
			text-transform: uppercase;
			margin-bottom:9px;
			display: block;
			color:#7d8386;
		}

		input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
		select,
		textarea {
			background-color: #f2f3f4;
			line-height: 1em;
			border:none;		
			font-size:16px;
			margin-bottom:0px;
			transition: $transition;
			font-family: $pFont;
			color:$grey-dark;
			border:1px solid transparent;
			&:focus {
				outline: none;
				border: 1px solid $grey-light;
			}
			&::placeholder {
				color:rgba($navy, 1);
			}
		}
		select {
			background-color: transparent;
			position: relative;
		}
		input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
		select {
			appearance:none;
			height: 46px;
			padding-left:10px;
			padding-right: 10px;
		}
		
		textarea {
			padding:20px 10px 10px;
		}
	}
	
	.gsection {
		border-bottom:0!important;
		margin-bottom:0!important;
		.gsection_title {
			font-weight: 500;
			color:$grey-dark;
			font-size: 23px;
		}
	}


	.ginput_container_checkbox input[type="checkbox"] {
	    opacity: 0;
	    position: absolute;
	    &:focus {
	    	outline:none;
	    }
	}

	.ginput_container_checkbox label {
	    position: relative;
	    display: inline-block;
	    overflow:visible!important;
	    
	    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
	    padding-left: 26px!important;
	    font-size:16px!important;
	}

	.ginput_container_checkbox label::before,
	.ginput_container_checkbox label::after {
	    position: absolute;
	    content: "";
	    
	    /*Needed for the line-height to take effect*/
	    display: inline-block;
	}

	/*Outer box of the fake checkbox*/
	.ginput_container_checkbox label::before{
	    height: 19px;
	    width: 19px;
	    background-color: #f2f3f4;
	    border:1px solid $grey-divider;
	    left: 0px;
	    border-radius:4px;
	    /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
	     *to vertically center it.
	     */
	    top: 3px;
	}

	/*Checkmark of the fake checkbox*/
	.ginput_container_checkbox label::after {
	    height: 5px;
	    width: 9px;
	    border-left: 2px solid;
	    border-bottom: 2px solid;
	    
	    transform: rotate(-45deg);
	    
	    left: 5px;
	    top: 9px;
	    color:#7b7c7f;
	}

	/*Hide the checkmark by default*/
	.ginput_container_checkbox input[type="checkbox"] + label::after {
	    content: none;
	}

	/*Unhide on the checked state*/
	.ginput_container_checkbox input[type="checkbox"]:checked + label::after {
	    content: "";
	}

	/*Adding focus styles on the outer-box of the fake checkbox*/
	.ginput_container_checkbox input[type="checkbox"]:focus + label::before {
	    border-color:$grey-med;
	}
	.gchoice_select_all, ul.gfield_checkbox li input[type=checkbox]:checked+label {
		font-weight: 400!important;
	}

	ul.gfield_checkbox > li {
		margin-bottom:15px!important;
	}

	/**
	 *	Validation Styles
	 */

	div.validation_error {
		border: 0;
		background-color: $error;
		color: #fff;
		font-weight: 500;
		padding: 10px 10px;
		display: block;
		margin-top: 30px;
		margin-bottom: 0;
		line-height: 1.5em;
		display: none;
	}

	&.gform_validation_error .top_label li.gfield.gfield_error {
		background-color: lighten($error, 5%);
		padding: 0px 10px;
		input[type="text"], input[type="email"], textarea {
			border: none;
			width:96%!important;
			margin-bottom:5px!important;
		}
		.ginput_container {
			padding-right: 10px;
		}
		.validation_message {
			color: #fff;
			font-weight: normal;
			line-height: 1.5em;
			padding-top: 6px;
			padding-bottom: 5px;
			font-size: 14px;
			text-align: left;
		}
	}

	li.gfield_error
		input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
	li.gfield_error textarea {
		background-color: rgba(255, 255, 255, 0.8);
		border: none;
		border-bottom: 1px solid $primary;
		padding-left: 10px;
		padding-right: 10px;
	}

	li.gfield_error label.gfield_label {
		color:white;
	}

	&.gform_validation_error
		.gform_body
		ul
		li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
		border-bottom: 0;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
