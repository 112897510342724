.block.banner {
	padding:0;
	overflow: hidden;
	position: relative;
	&:before {
		content:'';
		position:absolute;
		width:100%;
		height:100%;
		background: {
			image: url('../images/woodgrain_top.svg');
			size:100%;
			position:top;
			repeat:no-repeat;
		}
		left: 50%;
	    top: 328px;
	    transform: translateX(-50%);
	    z-index: 1;
	}
	
	.banner--content {
		margin-top: 0px;
	}
	.banner--background {
		z-index: 2;
		position: relative;
	}
	.banner--background-images-container {
		padding:0;
	}
	.banner--background-images {
		white-space: nowrap;
		padding:0;
		overflow: hidden;
	}

	.banner--background-image-item {
		padding:0;
	}

	.banner--background-image {
		display: inline-block;
		float: none;
		height: 400px;
		width:100%;
		background: {
			size:cover;
			position:center;
			repeat:no-repeat;
		}
		&.desktop {
			display: none;
		}
		&.mobile {
			display: inline-block;
		}
	}

	.banner--title {
		color:$slate;
		line-height: 1.32;
  		letter-spacing: -1.1px;
  		margin:0 0 15px 0;
	}
	
	.banner--description {
		font-size:20px;
		font-weight: 300;
		line-height: 1.6;
		margin-bottom:25px;
	}

	.banner--below-banner-intro {
		font-weight: 100;
		font-size:22px;
		text-align: center;
		line-height: 1.5em;
		margin-top:75px;
		margin-bottom:75px;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {


		

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {
		
		&:before {
			top:420px;
		}
		.banner--content {
			margin-top: 0px;
		}

		.banner--background-image {
			height: 525px;
		}

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.banner--content {
			margin-top: -190px;
		}

		.banner--background-image {
			height: 720px;
			&.desktop {
				display: inline-block;
			}
			&.mobile {
				display: none;
			}
		}


	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
		.banner--content {
			margin-top:-200px;
		}

		.banner--below-banner-intro {
			font-size:32px;
			margin-top:150px;
			margin-bottom:150px;
		}

	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}