@mixin bodyTypography() {
	font-size: 16px;
	line-height: 25px;
	font-weight: 400;
	letter-spacing: normal;
	font-family:$pFont;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}


@mixin bgImg($position, $size) {
	background: {
		position: $position;
		size:$size;
		repeat:no-repeat;
	}
}

@mixin overlay($color, $percentage) {
	position: relative;
	&:before {
		content:'';
		background-color:rgba($color, $percentage);
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
	}
}

@mixin gradientOverlay($deg, $color1, $color2) {
	background-blend-mode: multiply;
	background-image: linear-gradient(#{$deg}deg, $color1, $color2);
}

// list style mixin
@mixin list() {
  list-style-type:none;
  padding:0;
  margin:0;
}

@mixin headingAccent($color1, $color2, $position) {
	position:relative;
	padding-bottom:35px;
	&:before {
		content:'';
		width:100%;
		max-width:72px;
		height:2px;
		position:absolute;
		bottom:0;
		@if $position == center {
  			left:50%;
  			transform:translateX(-50%);
		} @else {
			left:0;
		}

		@if $color2 !=  '' {
			background-image: linear-gradient(180deg, $color1, $color2);
		} @else {
			background-color: $color1;
		}
	}
}

@mixin topDivider($divider_color) {
	&:before {
		content:'';
		position:absolute;
		left:50%;
		transform:translateX(-50%);
		display: block;
		width:120%;
		background: {
			size:100%;
			repeat:no-repeat;
		}
		margin-bottom:-1px;
		height: 150px;
		top:-145px;
		background-position: bottom center;

		@if $divider_color ==  'white' {
			background-image: url(../images/white-divider-top.svg);
		}
		@if $divider_color == 'grey' {
			background-image: url(../images/grey-divider_top.svg);
		}
		@if $divider_color == 'grey2' {
			background-image: url(../images/grey-divider_top-2.svg);
		}
	}
}

@mixin bottomDivider($divider_color) {
	position: relative;
	z-index: 2;
	&:after {
		height: 81px;
		bottom:-80px;
		content:'';
		position:absolute;
		left:50%;
		transform:translateX(-50%);
		display: block;
		width:100%;
		background: {
			size:100%;
			repeat:no-repeat;
		}
		z-index: 1;

		@if $divider_color ==  'white' {
			background-image: url(../images/white-divider-bottom.svg);
		}
		@if $divider_color == 'grey' {
			background-image: url(../images/grey-divider_bottom.svg);
			margin-top:-1px;
		}
	}

}

@mixin loading() {
	&.loading {
		&:before {
			content:'';
			position:absolute;
			height: 100%;
			width:100%;
			left:0;
			right:0;
			background-color:rgba(255,255,255,0.6);
			z-index: 2;
		}
		&:after {
			content:'';
			position:absolute;
			top:0;
			left:0;
			background-image:url('../images/loading.gif');
			width:100%;
			height:100%;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
}