.block.inspirations  {
	position: relative;
	padding-top:100px;
	@include topDivider('white');

	.ajax-container {
		position: relative;
		&.loading {
			&:after {
				content:'';
				position:absolute;
				top:0;
				left:0;
				top:0;
				left:0;
				background-color:rgba(255,255,255,0.6);
				background-image:url('../images/loading.gif');
			    height: 100%;
			    width: 100%;
			    background-repeat: no-repeat;
			    background-position: center 100px;
			}
		}
	}

	.inspiration--sidebar {
		background-color: $off-white;
		padding:25px;
		margin-bottom: 20px;
	}
	.inspiration--sidebar-title,
	.inspiration-tax-label {
		font-family: $pFont;
		color:$grey-dark;
		font-weight: 500;
	}
	.inspiration--sidebar-title {
		margin:0 0 25px 0;
	}
	.inspiration-tax-label {
		text-transform: uppercase;
		margin:20px 0 12px;
		letter-spacing:1.5px;
		font-size: 14px;
		&.inspiration--terms-list {
			padding-left:0;
		}
	}
	.inspiration-tax-label:not(.inspiration--all-item) {
		@include media-breakpoint-down(sm) {
			position: relative;
			cursor: pointer;
			&:after {
				@include iconFont();
				content: "\";
				position:absolute;
				right:20px;
				top:50%;
				transform:translateY(-50%) rotate(90deg);
				color:#7b7c7f;
				transition:$transition;
			}
			&.active {
				&:after {
					transform:translateY(-50%) rotate(-90deg);
				}
			}
		}
	}

	.inspiration--terms-list {
		@include list();
		padding-left: 15px;
    	font-size: 14px;
    	li {
    		margin-bottom:7px;
    	}
		a {
			color:$grey-dark;
			transition: $transition;
			text-decoration: none;
			&:hover,
			&.active {
				color: $primary;
				text-decoration: underline;
			}

		}
		ul {
			padding-left:10px;
			list-style-type:none;
			margin-top: 7px;
			li {
				position: relative;
				&:before {
					content:'- ';
				}

			}
		}
	}
	.inspiration--item {
		break-inside: avoid;
		position: relative;
		margin-bottom:20px;
	}

	.inspiration--image {
		position: relative;
		img {
			display: block;
		}
		&:hover {
			.inspiration--hover {
				opacity: 1;
			}
			.inspiration--hover-text {
				opacity: 1;
				top:0;
			}
		}
	}
	
	.inspiration--item-link-wrap {
		display: block;
	}

	.inspiration--hover {
	    display: block;
	    position: absolute;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    top: 0;
	    z-index: 10;
	    background-color: $secondary;
	    padding: 20px;
	    display: flex;
	    align-items: center;
	    opacity: 0;
	    transition: .5s;
	    backface-visibility: hidden;

		&:before {
			transition:opacity 0.3s ease;
			@include iconFont;
			content: "\";
			position: absolute;
			bottom:20px;
			right:20px;
			font-size: 45px;
			color:$grey-dark;
		}
	}


	
	.inspiration--hover_content {
	    -ms-flex: 1;
	    flex: 1;
	    text-align: center;
	    padding: 20px 30px;
	}
	.inspiration--hover-text {
	    margin: 0;
	    color: #494949;
	    position: relative;
	    top: -20px;
	    opacity: 0;
	    transition: .3s;
	    transition-delay: .2s;
	    font-size: 24px;
	    font-family: $pFont;
	}

	.inspiration--item-info {
		margin-top:10px;
		p {
			margin-bottom:5px;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.inspiration--sidebar {
			margin-bottom: 0;
		}
		.inspiration--item {
			padding-left:10px;
			padding-right:10px;
		}



	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
		
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}