// Colours
$orange: #ee9f40;
$off-white: #fafafa;
$navy: #344348;
$blue: #2a6682;
$slate: #546872;


$error: #d52d2d;
$paleblue: #2a6582;
// greys
$grey-dark: #324249;
$grey-med: #7b7c7f;
$grey-light: #d7d8da;

$grey-divider: #bbc2c6;

$primary: $blue;
$secondary: $orange;

$pColor: $navy;
$hColor: $navy;

// Fonts
$ivy: 'ivyjournal', sans-serif;
$azo: 'azo-sans-web', sans-serif;

$pFont: $azo;
$hFont: $ivy;

// Other
$transition: all 0.3s ease;

$header-mobile-height: 55px;
$header-desktop-height: 72px;