.post-index {

	padding:80px 0 0px;
	
	article.post {
		padding-bottom:50px;

		.post-title {
			margin-top:20px;
			margin-bottom:5px;
			a {
				transition: $transition;
			}
		}
		.post-date {
			font-style: italic;
			color:$secondary;
			margin-bottom: 5px;
			display: block;
		}
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}