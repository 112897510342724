body {

	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	
	font-family: $pFont;
	overflow-x:hidden; // some waves extend past the container.
	/**
	 *  containe container
	 */
	
	::selection {
	  background: $primary; 
	  color: white;
	}

	.contained-container {
		max-width:1400px;
	}

	@include media-breakpoint-down(sm) {
		.sm-mb-2 {
			margin-bottom: 20px;
		}

		.sm-mb-4 {
			margin-bottom: 40px;
		}
	}

	@include media-breakpoint-down(md) {
		.md-mb-2 {
			margin-bottom: 20px;
		}

		.md-mb-4 {
			margin-bottom: 40px;
		}
	}

	/**
	 * Gutenberg styling Horizontal rule <hr>
	 */
	hr.wp-block-separator {
	    opacity: 0.3;
	}

	main.main {
		margin-top:$header-mobile-height;
		overflow: hidden;
	}

	.wave-divider {
		img {
			display: block;
		}
	}

	/**
	 * Mobile overlay
	 */

	@include media-breakpoint-down(md) {
		main.main,
		footer.footer-content {
			&:before {
				content:'';
				position: absolute;
				left:0;
				top:0;
				width: 100%;
				height: 100%;
				background-color:rgba(0,0,0,0.75);
				visibility: hidden;
				opacity:0;
				transition: opacity 0.2s ease 0s, visibility 0s ease 0.2s;
				z-index:80;
			}
		}
		&.menu-open {
			main.main,
			footer.footer-content {
				position: relative;
				&:before {
					opacity: 1;
					visibility: visible;
					transition-delay: 0s, 0s;
				}
			}
		}
	}
	
	/**
	 * Gutenberg blocks
	 */
	.wp-block-button__link {
	    @extend .btn;
	    @extend .btn.primary;
	    border-radius:0;
	}

	/**
	 *  No Posts messaging
	 */
	
	.message.no-posts {
		background-color: #f2f3f4;
		color:#7b7c7f;
		padding:10px 15px;
		a {
			color:#7b7c7f;
			transition:$transition;
			&:hover {
				color:$primary;
			}
		}
	}

	ul {
		&.stylized-list {
			list-style-type:none;
			padding-left:20px;
			li {
				position: relative;
				margin-bottom:1.1em;
				padding-top:3px;
				&:before {
					content:'•';
					position: absolute;
					left:-20px;
					top:0px;
					font-size:35px;
					color:$grey-divider;
				}
			}
		}
	}

	.wysiwyg ul {
		@extend .stylized-list;
	}

	p.global-disclaimer {
		font-size: 13px;
		color: $slate;
		line-height: 1.62;
	}

	label.sub-heading {
		font-weight: 600;
		font-size: 16px;
		line-height: 1.25;
	}

	.wrap,
	.wysiwyg {
		position: relative;

		img {
			max-width: 100%;
			height: auto;
		}
		p {
			a {
				color: $primary;
				transition: $transition;
				text-decoration: underline;
				font-weight: bold;
				&:hover {
					color:$grey-med;
				}
			}
		}

		/* =============================================================================
		Extra Small +
		========================================================================== */
		@include media-breakpoint-up(xs) {
		}

		/* =============================================================================
		Small +
		========================================================================== */
		@include media-breakpoint-up(sm) {
		}

		/* =============================================================================
		Medium +
		========================================================================== */
		@include media-breakpoint-up(md) {




		}

		/* =============================================================================
		Large +
		========================================================================== */
		@include media-breakpoint-up(lg) {
			
			main.main {
				margin-top:$header-desktop-height + 30px; // add utility bar height
			}

		}

		/* =============================================================================
		Extra Large +
		========================================================================== */
		@include media-breakpoint-up(xl) {
		}
	}
}
