ul.social-icons {
	@include list();
	margin-top:25px;
	li {
		display: inline-block;
		margin-right: 15px;
		&:last-child {
			margin-right: 0;
		}
		&:hover {
			span.icon {
				background-color:$primary;
				border-color:$primary;
				color:black;
			}
		}
		a {
			color:$grey-dark;
			text-decoration: none;
			font-size:20px;
			transition:color 0.3s ease;
			&:hover {
				color:$secondary;
			}
		}
	}
}