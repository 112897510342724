.featured-post-item {
	
	.featured-post-item--inner {
		@include media-breakpoint-down(sm) {
			background: none!important; //override the background here to display a separate mobile element
		}
	}

	.featured-post-item--mobile_image {
		display: block;
		padding-bottom: 70%;
		display: block;
		background: {
			size:cover;
			position:center;
			repeat:no-repeat;
		}
	}

	.featured-post-item--content {
		background-color:white;
		padding:25px 0 20px;
	}

	.featured-post-item--title {
		margin:0 0 15px;
		font-family: $pFont;
		font-weight: 500;
		line-height: 1.48;
		color: $grey-dark;
		a {
			transition:$transition;
			color: $grey-dark;
			text-decoration: none;
			&:hover {
				color:$secondary;
			}
		}
	}
	
	.featured-post-item--description {
		font-size: 20px;
		line-height: 1.6;
		color:$grey-dark;
		font-weight: 300;
		margin-top: 15px;
		&.post {
			margin-bottom: 25px;
		}
	}
	.featured-post-item--link-wrap {
		display: block;
		border-top:1px solid $grey-divider;
		padding-top:20px;
		margin-top: 30px;	
	}
	
	.featured-post-item--date {
		text-align: right;
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.featured-post-item--mobile_image {
			display: none;
		}

		.featured-post-item--inner {
			padding:190px 55px 55px 55px;
			background: {
				size:cover;
				position:center;
				repeat:no-repeat;
			}
		}

		.featured-post-item--content {
			padding:40px;
			max-width:460px;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}