.menus.desktop {
	display: none;
	width: auto;

	ul.nav {
		list-style-type: none;
		padding: 0;
		text-align: right;
		margin: 0;
		&.style-last-menu-item {
			& > li {
				&:last-child {
					padding:18.5px 0;
					a {
						background-color: $primary;
						color:white;
						border-radius: 5px;
						padding:5px 13px;
					}
				}
			}
		}

		& > li {
			display: inline-block;
			margin-left: 35px;
			padding:23.5px 0;
			a {
				display: block;
				text-decoration: none;
				color: $pColor;
				transition: $transition;
				font-weight: 400;
				font-family: $pFont;
				font-size: 18px;
				position: relative;
				&.active {
					color:$secondary;
				}
			}
			&:hover {
				.mega-menu {
					opacity: 1;
					visibility: visible;
					transition-delay: 0s, 0s;
					transform: translateY(0px);
				}
			}
			&.stylized-menu-item {
				position: relative;
				z-index: 2;// the subnav overlaps this button.. here's a quick hack
				.link-wrapper {
					a {
						background-color: $primary;
						color: white;
						padding: 5px 13px;
						margin-top: -5px;
						border-radius: 5px;
						transition:$transition;
						&:hover {
							background-color: $secondary;
							color:white;
						}
					}
				}
			}
			&.has-mega {
				
				.link-wrapper {
					position:relative;
					&:before,
					&:after {
						content:'';
						position:absolute;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 20px 20px 20px;
						left:50%;
						transform:translate(-50%, 10%);
						z-index: 2;
						opacity: 0;
						visibility: hidden;
						transition: opacity 0.2s ease 0s, visibility 0s ease 0.1s, transform 0.2s ease 0.1s;
					}
					&:after {
						border-color: transparent transparent $off-white transparent;
						bottom:-24px;
					}
					&:before {
						border-color: transparent transparent $grey-divider transparent;
						bottom:-23px;
					}
				}
				&:hover {
					.link-wrapper {
						&:before,
						&:after {
							opacity: 1;
							visibility: visible;
							transform:translate(-50%, 0);
						}
						& > a {
							color:$secondary;
						}
					}
				}
				
			}
			.link-wrapper {
				&:hover {
					> a {
						color:$secondary;
					}
					.mega-menu {
						opacity: 1;
						visibility: visible;
						transition-delay: 0s, 0s;
						transform: translateY(0px);
					}
					.link-wrapper {
						&:before {
							height: 6px;
						}
					}
				}
				&:hover {
					ul.subnav {
						opacity: 1;
						visibility: visible;
						transition-delay: 0s, 0s;
					}
				}
				ul.subnav {
					@include list();
					position: absolute;
					background-color:white;
					text-align: left;
					width:auto;
					padding:30px 20px 20px 20px;
					opacity: 0;
					visibility: hidden;
					z-index: 1;
					transition: opacity 0.2s ease 0s, visibility 0s ease 0.2s, transform 0.2s ease 0s;
					li {
						padding-right: 20px;
						font-weight: 500;
						line-height: 1.89;
						color: $grey-dark;
						white-space: nowrap;
						border-bottom:1px solid $grey-divider;
						padding-bottom: 5px;
						margin-bottom:5px;
						&:last-child {
							border-bottom: 0;
							margin-bottom: 0;
							padding-bottom: 0;
						}
						&.active {
							a {
								color:$blue;
								&:after {
									color:$secondary;
									opacity: 1;
									transform:translate(0, -50%);
								}
							}
						}
						a {
							font-size: 18px;
							display: inline-block;
							line-height: 1em;
							&:after {
								@include iconFont();
								content: "\";
								position:absolute;
								top:50%;
								transform:translate(-20%, -50%);
								right:-15px;
								font-size:10px;
								opacity: 0;
								transition:transform 0.3s ease, opacity 0.3s ease;
							}
							&:hover {
								color:$blue;
								&:after {
									color:$secondary;
									opacity: 1;
									transform:translate(0, -50%);
								}
							}
						}
					}
				}
			}
			.mega-menu {
				top: 100%;
				opacity: 0;
				visibility: hidden;
				z-index: 1;
				transition: opacity 0.2s ease 0s, visibility 0s ease 0.2s, transform 0.2s ease 0s;
				transform: translateY(-10px);
				background-color: $off-white;
				@include list();
				position: absolute;
				left: 0px;
				text-align: left;
				width: 100vw;
				border-top: 1px solid $grey-divider;
				border-bottom: 1px solid $grey-divider;
				padding: 60px 0 40px;
				max-height: calc(100vh - 104px);
				overflow-y: auto;
				.mega-nav--title {
					color:$paleblue;
					font-size:25px;
					font-weight: 600;
					margin:0 0 40px 0;
					&.mega-menu--right-column-title {
						margin-bottom: 28px;
					}
				}
				.mega-menu--left-column-links-wrap {
					margin-left:-5px;
					margin-right: -5px;
					.col-6 {
						padding-left:5px;
						padding-right:5px;
					}
					a.mega-menu--left-link-item {
						background: {
							size:cover;
							position:center;
						}
							padding-bottom:100%;
							width:100%;
							position:relative;
						&:before {
							content:'';
							position:absolute;
							top:0;
							left:0;
							height: 100%;
							width:100%;
							background-color:rgba(black,0.22);
							transition:background-color 0.3s ease;
						}
						&:hover {
							&:before {
								background-color:rgba(black,0.42);	
							}
						}
						.mega-menu--left-link-title {
							position:absolute;
							width: 100%;
							text-align: center;
							font-size: 46px;
							line-height: 1em;
							color:white;
							z-index: 1;
							top:50%;
							transform:translateY(-50%);
							font-weight: 600;
							line-height: 1.15;
							letter-spacing: -0.9px;
							font-family: $hFont;
						}
					}
				}

				.mega-menu--product-links {
					columns:2;
				}
				.mega-menu--menu {
					@include list();
					break-inside: avoid;
					a {
						display: inline-block;
						line-height: 1em;
						&:after {
							@include iconFont();
							content: "\";
							position:absolute;
							top:50%;
							transform:translate(-20%, -50%);
							right:-15px;
							font-size:10px;
							opacity: 0;
							transition:transform 0.3s ease, opacity 0.3s ease;
						}
						&:hover {
							&:after {
								color:$secondary;
								opacity: 1;
								transform:translate(0, -50%);
							}
						}
					}
				}
				.mega-menu--menu_series {
					display: block;
					position: relative;
					margin-bottom:7px;
					padding-bottom:7px;
					&:after {
						content:'';
						position: absolute;
						left:0;
						bottom:0;
						width:100%;
						height: 1px;
						background-color:$grey-divider;
					}
					a {
						font-size: 18px;
						font-weight: 500;
						line-height: 1.89;
						color:$grey-dark;
						&:hover {
							color:$primary;
						}
					}
				}
				.mega-menu--menu_products {
					@include list();
					li:last-child {
						margin-bottom: 10px;
					}
					a {
						font-size: 11px;
						font-weight: 500;
						letter-spacing: 1.1px;
						color:$grey-dark;
						text-transform: uppercase;
						position: relative;
						display: inline-block;
						line-height: 1em;
						&:hover {
							color:$secondary;
						}
					}
				}
			}
		}
	}

	.mega-menu--menu-series-description {
		font-size: 11px;
		font-weight: 500;
		letter-spacing: 1.1px;
		color: #324249;
		text-transform: uppercase;
		position: relative;
		display: inline-block;
		line-height: 1.7em;
		opacity: 0.7;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		display: block;
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
