.footer-callouts {
	padding:60px 0 60px;
	text-align: center;
	
	.footer-callouts--title-container {
		border-bottom:1px solid $grey-divider;
	}

	.footer-callouts--link-wrap {
		margin-top: 25px;
	}


	.footer-callouts-inner {
		margin-bottom:60px;
	}

	.footer-callouts--title-container {
		position: relative;
	}

	.footer-callouts--title_link {
		position: absolute;
		top: 0;
		left:0;
		width:100%;
		height: 100%;
		display: block;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		text-align: left;
		padding:110px 0 90px;
		.footer-callouts-inner {
			margin-bottom: 0;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}


/**
 * Globalized for lightbox content
 */

.footer-callouts--title {
	margin:0 0 25px 0;
	line-height: 1.15em;
	letter-spacing:-0.75px;
	position: relative;
	&:after {
		content:'';
		position:absolute;
		left:50%;
		top:50%;
		transform:translate(-50%,-50%);
		background: {
			size:cover;
			position:center;
			image:url('../images/woodgrain_overlay.png');
			repeat:repeat;
		}
		width:100%;
		height: 100%;
		opacity: 0.4;
	}

    font-family: $hFont;
    line-height: 1em;
    font-weight: 600;
    font-size:48px;

}

.footer-callouts--title_secondary {
	color:$secondary;
}

.footer-callouts--title_primary {
	color:$primary;
}

.footer-callouts--lightbox_content {
	background-color: white;
	padding: 40px;
	max-width: 691px;
	overflow-y: scroll;
	p {
		color:$grey-dark;
		font-size: 16px;
		//max-width:457px;
		line-height: 1.56;
	}
	
	.gform_footer {
		margin-top: 30px!important;
		padding-top: 40px!important;
		border-top:1px solid $grey-divider;
	}

}