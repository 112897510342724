.block.faq {
	position: relative;
	padding-top: 100px;

	&.enable-wave-divider {
		@include topDivider('white');
	}

	.faq--title {
		color:$primary;
		margin:0 0 50px 0;
	}

	.faq--divider {}
	.faq--content {}
	.faq--item {
		padding-bottom:25px;
		margin-bottom:25px;
		border-bottom:1px solid $grey-divider;
		&:last-child {
			margin-bottom:0;
			padding-bottom:0;
			border-bottom:0;
		}
	}
	.faq--question {
		font-family: $pFont;
		font-weight: 500;
		line-height: 1.39;
		position: relative;
		margin:0 0;
		cursor: pointer;
		padding-right: 35px;
		&.active {
			&:after {
				transform:rotate(-90deg);
			}
		}
		&:after {
			@include iconFont;
			content: "\";
			transform:rotate(90deg);
			position:absolute;
			top:8px;
			right:0px;
			transition:$transition;
			font-size: 20px;
			color:$grey-divider;
		}
	}
	.faq--answer {
		display: none;
		padding:20px 0;
		font-weight: 300;
		line-height: 1.6;
		color: #253239;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		.faq--answer {
			font-size: 20px;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}