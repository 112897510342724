.block.series-product {
    --colPadding: 2px;
    --colWidth: calc((1/3 * 100%) - ((1 - 1/3) * 1rem));

    &.top-white-divider {
		position: relative;
		@include topDivider('white');
		margin-top: 0;
        background-color: $white;
        
        overflow: hidden;
	}

    .row {
        align-items: flex-start;
        padding-top: 2rem;
    }
    
    .series-product--title,
    .series-product--panel-title {
        display: flex;
        justify-content: space-between;
        font-family: $pFont;
        font-size: 1.125rem;
        padding-bottom: 1rem;
        border-bottom: 1px $grey-divider solid;
        text-transform: capitalize;


        i.icon-right-chevron {
            cursor: pointer;
            transform: rotate(90deg);
            color: $grey-divider;
            transition: transform 300ms ease;
        }
    }

    .series-product--panel-title {
        font-size: 11px;
        line-height: 25px;
        border-bottom: none;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1.1px;
        padding-bottom: 0;
        margin: 0;
    }

    .series-product--product-info-container {
        margin-top: 25px;
    }

    .series-product--product-info {
        font-size: 13px;
        margin-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px $grey-divider solid;

        ul:not([class]) {
            padding-left: 20px;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 1.1em;
                padding-top: 3px;

                &:before {
                    content: "•";
                    position: absolute;
                    left: -20px;
                    top: 0;
                    font-size: 35px;
                    color: #bbc2c6;
                  }
            }
        }
    }

    .series-product--step-title {
        color: $paleblue;
        
        & > small {
            font-weight: 400;
            font-family: $pFont;
            text-transform: capitalize;
        }
    }

    .series-product--selected-item {
        text-transform: uppercase;
        font-size: 0.75rem;
    }

    .series-product--color-list,
    .series-product--profile-list,
    .series-product--product-info--substrates {
        display: flex;
        list-style: none;
        padding: 0;
        flex-wrap: wrap;
        
        .series-product--color,
        .series-product--profile,
        .series-product--product-info--substrate {
            position: relative;
            height: 0;
            padding: var(--colPadding);
            overflow: hidden;
            cursor: pointer;
            border: 1px transparent solid;
            width: var(--colWidth);
            padding-bottom: calc(var(--colWidth) - (var(--colPadding) * 2));
            
            &.selected {
                border-color: $black;
            }

            & > label {
                position: absolute;
                z-index: 99;
                bottom: 0;
                left: var(--colPadding);
                right: var(--colPadding);
                text-align: center;
                background: white;
                border: 1px lightgray solid;

                & + img {
                    position: relative;
                    z-index: 9;
                }
            }
        }
        
        .series-product--product-info--substrate {
            cursor: initial;
        }
    }

    .series-product--trim-wrap {
        --colWidth: calc((1/4 * 100%) - ((1 - 1/4) * 1rem));
    }

    .series-product--color-list {
        --colWidth: calc((1/6 * 100%) - ((1 - 1/6) * 0.25rem));

        gap: 0.25rem;
    }

    .series-product--trim-wrap {
        display: flex;
        gap: 1rem;
        align-items: center;

        @supports (-webkit-touch-callout: none) and (not (translate: none)) {
            .series-product--trim-title {
                margin-left: 1rem;
            }
        }
        
        .series-product--trim-color {
            width: var(--colWidth);
            height: 0;
            padding-bottom: var(--colWidth);
            overflow: hidden;
        }
        
        .series-product--trim-title {
            line-height: 1rem;
            font-family: $pFont;
            font-weight: 400;

            & > small {
                font-weight: 500;
                display: block;
                font-size: 100%;
            }
        }
    }

    .series-product--order {
        display: flex;
        flex-wrap: nowrap;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem;
        border: 2px solid $orange;
        width: 100%;

        @supports (-webkit-touch-callout: none) and (not (translate: none)) {
            .series-product--order-description {
                margin-left: 1rem;
                margin-right: 1rem;
            }

            .series--product-link-wrap {
                margin-top: 1rem;
            }
        }

        .series--product-link-wrap {
            a.btn {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        & > img {
            width: 3rem;
            height: 3rem;
        }

        .series-product--order-description {
            flex: 1;
            text-align: center;

            & > h6 {
                font-size: 1rem;
                font-weight: 600;
                margin-top: 0;
                margin-bottom: 0;

                & > small {
                    display: none;
                }
            }
        }
    }

    .series-product--gallery {
        @include slick-round-buttons();

        margin-top: 2rem;

        .series-product--gallery-image img {
            width: 100%;
        }
    }

    .table {
        width: 100%;
        font-size: calc(0.875rem - 1px);
        text-align: left;

        thead > tr > th {
            padding-bottom: 0.625rem;
            font-size: 0.875rem;
            font-weight: 500;
        }
    }

    ul.single-product--resources-list {
		@include list();

		margin:20px 0;

		li {
			line-height: 1em;
			i {
				margin-right: 5px;
				vertical-align: -2px
			}
			a {
				color:$slate;
				transition:$transition;
				&:hover {
					color:$secondary;
				}
			}
		}
		@include media-breakpoint-down(sm) {
			li {
				margin-bottom: 25px;
				i {
					font-size:24px;
					vertical-align:middle;
				}
				a {
					font-size: 16px;
				}
			}
		}
	}
    
    .single-product--colour-disclaimer {
		font-size: 13px;
		color: $slate;
		display: inline-block;
	}

    .single-product--small-link {
		font-size: 13px;
		text-decoration: underline;
		color: $slate;
		transition: $transition;
		&:hover {
			color: $secondary;
		}
	}

    .color-disclaimer-modal {
        position: fixed;
        inset: 0;
        overflow-y: auto;
        z-index: 9999;
    }

    .color-disclaimer-modal-overlay {
        position: fixed;
        inset: 0;
        background: rgba(0, 0, 0, 0.5);
    }

    .color-disclaimer-modal-content-wrap {
        position: relative;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
    }

    .color-disclaimer-modal-content {
        position: relative;
        max-width: 42rem;
        width: 100%;
        background: #FFFFFF;
        border: 1px #000000 solid;
        border-radius: 0.5rem;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
        padding: 3rem;
        overflow-y: auto;

        a {
            font-weight: 400;
        }

        a:hover {
            color: #ee9f40;
        }
    }

    .color-disclaimer-modal-title {
        font-size: 1.875rem;
        line-height: 2.25rem;
        font-weight: 700;
        display: none;
    }

    /* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {
        .series-product--profile-resource {
            --colWidth: calc((1/2 * 100%) - ((1 - 1/2) * 2.5rem))
        }
	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {
        .series-product--trim-wrap,
        .series-product--color-list {
            --colWidth: calc((1/4 * 100%) - ((1 - 1/4) * 1rem));
        }

        .series-product--order-container {
            display: inline-block;
            min-width: 50%;
        }

        .series-product--order {
            gap: 2rem;
            padding: 1.5rem;
    
            .series-product--order-description {
                text-align: left;

                & > h6 {
                    font-size: 1.2rem;

                    & > small {
                        display: block;
                        margin-bottom: 8px;
                        font-weight: 400;
                        font-family: $pFont;
                        font-size: 1rem;
                    }
                }
            }
        }
	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
        .series-product--order {
            & > img {
                width: 4rem;
                height: 4rem;
            }

            .series-product--order-description {
                & > h6 {
                    font-size: 1.5rem;
                }
            }
        }

        .series-product--profile-resource {
            --colWidth: calc((1/3 * 100%) - ((1 - 1/3) * 2.5rem))
        }

        .series-product--product-info--substrate {
            --colWidth: calc((1/3 * 100%) - ((1 - 1/3) * 1rem));
            cursor: unset;
        }

        .series-product--profile {
            --colWidth: calc((1/2 * 100%) - ((1 - 1/2) * 1rem));
        }

        .series-product--gallery {
            @include slick-round-buttons();
    
            margin-top: 0;
        }
	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
        &.top-white-divider {
            overflow: visible;
        }

        .series-product--trim-wrap,
        .series-product--color-list {
            --colWidth: calc((1/6 * 100%) - ((1 - 1/6) * 1rem));
        }

        .series-product--product-info--substrate {
            --colWidth: calc((1/4 * 100%) - ((1 - 1/4) * 1rem));
            cursor: unset;
        }

        .series-product--profile {
            --colWidth: calc((1/4 * 100%) - ((1 - 1/4) * 1rem));
        }

        ul.single-product--resources-list {
            columns: 2
        }
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
        .series-product--trim-wrap,
        .series-product--color-list {
            --colWidth: calc((1/8 * 100%) - ((1 - 1/8) * 1rem));
        }

        .series-product--product-info--substrate {
            --colWidth: calc((1/5 * 100%) - ((1 - 1/5) * 1rem));
            cursor: unset;
        }
	}
}

.block.series-product.is-block-editor {
    overflow: hidden;
    
    .series-product--gallery {
        position: relative;
    
        .series-product--gallery-image {
            position: absolute;
            inset: 0;
        }
    }
}