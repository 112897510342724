.content-team-member {
	margin-bottom:52px;
	.content-team-member--portrait {
		padding-bottom:100%;
		width:100%;
		background: {
			size:cover;
			position:center;
		}
		border:6px solid white;
	}

	
	.content-team-member--name {
		margin:16px 0;
		font-family: $pFont;
		color:$grey-dark;
		font-weight: 500;
		line-height: 1.39;
	}
	.content-team-member--position,
	.content-team-member--department {
		margin:0 0 5px 0;
		font-size: 16px;
		line-height: 1.56;
		color:$grey-dark;
	}
	.content-team-member--location.grey-sub {
		text-transform: uppercase;
		margin:20px 0 30px 0;
	}
	.content-team-member--bio-container {
		border-top:1px solid #bbc2c6;
		border-bottom:1px solid #bbc2c6;
		padding:10px 0;
	}
		.content-team-member--bio-toggle {
			display: block;
			text-decoration: none;
			font-size: 11px;
			font-weight: 500;
			letter-spacing: 1.1px;
			text-transform: uppercase;
			color:$grey-dark;
			position: relative;
			&:after {
				@include iconFont();
				content: "\";
				position:absolute;
				right:0px;
				top:50%;
				transform:translateY(-50%) rotate(90deg);
				color:#bbc2c6;
				font-size: 14px;
				transition:transform 0.3s ease;
			}
			&.open {
				&:after {
					transform:translateY(-50%) rotate(-90deg);
				}
			}
		}
		.content-team-member--bio {
			display: none;
			font-size: 13px;
			line-height: 1.62;
			color:$grey-dark;
			padding-top: 10px;
		}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}