.block.image-callout {
	position: relative;
	padding:0;
	background-color:$off-white;

	.image-callout--image-wrap {
		padding-bottom: 64%;
		margin-top: 77px;
		background: {
			position:center;
			size:cover;
			repeat:no-repeat;
		}
	}

	.image-callout--content {
		padding:50px 0 60px;
		text-align: center;
	}
	.image-callout--title {
		margin:0 0 40px 0;
		line-height: 1.32;
  		letter-spacing: -1.1px;
  		color: $slate;
	}
	.image-callout--description {
		margin-bottom: 30px;
		font-size: 20px;
		font-weight: 300;
		line-height: 1.6;
		color:$grey-dark;
	}
	.image-callout--links-wrapper {
		a {
			margin-right:25px;
			@include media-breakpoint-down(sm) {
				margin-right: 0;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.image-callout--image-wrap {
			padding-bottom:0;
			margin-top: 0;
			position:absolute;
			height:100%;
			width: 54%;
			left:0;
			top:0;
		}

		.image-callout--content {
			padding:152px 0 90px;
		}

		.image-callout--content {
			text-align: left;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}