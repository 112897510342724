blockquote {
	background: transparent;
	margin: 0 0 20px;
	font-style: italic;
	padding: 0;
	text-align: center;

	cite {
		display: block;
		font-size: 18px;
		margin-top: 17px;
	}

	p:last-of-type {
		margin-bottom: 0;
	}

	&.is-large.wp-block-quote {
		margin: 0 0 20px;
		padding: 0;

		cite {
			text-align: center;
		}
	}

	&.wp-block-pullquote {
		border-top: 2px solid $primary;
		border-bottom: 2px solid $primary;
		padding: 20px 0;
	}
}