$dividerHeight_top: 116px;
$dividerHeight_bottom: 80px;

.block.double-image-callout  {
	padding:0;
	background-color: black;
	.double-image-callout--title-container {
		background-color:$off-white;
		position:relative;
		z-index: 2;
		&:before,
		&:after {
			content:'';
			position:absolute;
			left:50%;
			transform:translateX(-50%);
			display: block;
			width:109%;
			background: {
				size:100%;
				repeat:no-repeat;
			}
		}

		&:before {
			margin-bottom:-1px;
			height: $dividerHeight_top + 1;
			top:-#{$dividerHeight_top};
			background-image: url('../images/grey-divider_top.svg');
			background-position: bottom center;
		}
		&:after {
			margin-top:-1px;
			height: $dividerHeight_bottom + 1;
			bottom:-#{$dividerHeight_bottom};
			background-image: url('../images/grey-divider_bottom.svg');
			background-position: top center;
		}

		.container {
			position: relative;
			z-index: 3;
		}
	}
	.double-image-callout--title {
		margin:0;
		color:$slate;
		text-align: center;
		padding:50px 0;
		font-weight: 600;
		line-height: 1em;
  		letter-spacing: -1.1px;
	}
	

	.double-image-callout--callouts-container {
		position: relative;
		z-index: 1;
	}

	.double-image-callout--callout {
		cursor: pointer;
		background-color:black;
		padding:125px 0 100px;
		text-align: center;
		position:relative;
		transition:$transition;
		overflow:hidden;
		&:hover {
			.double-image-callout--callout-bg-image {
				transform:scale(1.05);
				&:after {
					opacity: 1;
				}
			}
		}
		.double-image-callout--callout-bg-image {
			position:absolute;
			background: {
				position:center;
				size:cover;
				repeat:no-repeat;
			}
			top:0;
			left:0;
			width:100%;
			height:100%;
			transition:$transition;
			transform:scale(1);
			&:before,
			&:after {
				content:'';
				position:absolute;
				width:100%;
				left:0;
				bottom:0;
			}
			&:before {
				height: 65%;
				background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.67));
			}
			&:after {
				height: 100%;
				background-color:rgba(0,0,0,0.1);
				opacity: 0;
				transition:$transition;
			}
		}
	}
	.double-image-callout--callout-title {
		margin:0;
		color:white;
		position: relative;
		z-index: 1;
	}
	.double-image-callout--link-wrap {
		margin-top:15px;
		position: relative;
		z-index: 1;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.double-image-callout--title {
			padding:20px 0 20px;
		}

		.double-image-callout--callout {
			padding:450px 0 50px;
		}
		.double-image-callout--link-wrap {
			margin-top:30px;
		}
	

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {


	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}