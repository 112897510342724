.file-resources {
    
    .file-resources--section-title {
        font-size: 2rem;
        font-family: $pFont;
        text-align: center;
        border-bottom: 1px solid $grey-divider;
        padding-bottom: 3.75rem;
        margin-bottom: 3.75rem;
        font-weight: 500;
    }
    
    .file-resources--resources {
        display: flex;
        flex-wrap: wrap;
        gap: 2.5rem;
        padding: 0;
    }
    
    .file-resources--resource {
        --colWidth: 100%;
    
        width: var(--colWidth);
        background: $off-white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        padding: 1rem;
    }
    
    .file-resources--resource-title {
        font-family: $pFont;
        font-weight: 400;
        margin-bottom: 0.825rem;
        margin-top: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    /* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {
        .file-resources--resource {
            --colWidth: calc((1/2 * 100%) - ((1 - 1/2) * 2.5rem))
        }
	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
        .file-resources--resource {
            --colWidth: calc((1/3 * 100%) - ((1 - 1/3) * 2.5rem))
        }
	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
        //	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
        //
	}
}