.related-posts {
	padding:90px 0 50px;
	background: {
		color: $off-white;
		image:url('../images/woodgrain_greywhite.svg');
		size: cover;
		position: center;
	}

	.related-posts--title {
		font-family: $pFont;
		text-align: center;
		font-weight: 500;
		margin:0;
		color:$grey-dark;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}