.product-template {

	.single-product--small-link {
		font-size: 13px;
		text-decoration: underline;
		color: $slate;
		transition: $transition;
		&:hover {
			color: $secondary;
		}
	}

	.single-product--section-divider {
		padding: 0;
		border-top: 1px solid $grey-divider;
	}

	.single-product--wrap {
		padding-top: 30px;
		background: {
			size: cover;
			position: center;
			image: url("../images/wood-grain-greywhite_tall.svg");
			color: $off-white;
		}
	}

	.single-product--series {
		text-transform: uppercase;
	}
	.single-product--title {
		margin: 0 0 20px;
		font-weight: 600;
		line-height: 1.32;
		letter-spacing: -1.1px;
		color: $slate;
		@include media-breakpoint-down(md) {
			font-size: 32px;
		}
	}

	.single-product--description {
		font-size: 16px;
		line-height: 1.56;
		color: $grey-dark;
		margin-bottom: 30px;
	}

	.single-product--links-wrap {
		margin-bottom: 15px;
		a.btn {
			margin-bottom:15px;
			&:first-child {
				margin-right: 40px;
			}
			padding: 8px 24px;
		}
	}

	@include media-breakpoint-down(sm) {
		.single-product--colours {
			border-top:none;
			padding:0px 0 30px;
		}
		.single-product--section-divider {
			padding:13px 0;
		}
	}

	.single-product--colour-disclaimer {
		font-size: 13px;
		color: $slate;
		display: inline-block;
	}

	.single-product--textures {
		@include list();
		margin-left: -8px;
		position: relative;
		@include loading();
		padding-bottom:20px;
	}
	.single-product--texture {
		display: inline-block;
		@include media-breakpoint-down(sm) {
			.product-template-template-product & {
				width:27%;
			}
		}
		width:100%;
		margin: 8px;
		position: relative;
		vertical-align:top;
		&:before {
			border:4px solid transparent;
			transition:border 0.3s ease;
			top: 0;
			left:0;
			background-color: transparent;
			content:'';
			position:absolute;
			height:100%;
			width:100%;
		}

		/*removed hover state, functionality deprecated */

		/*  
		&.active,
		&:hover {
			&:before {
				border:4px solid $primary;
			}
			.single-product--texture_name {
				background-color:$primary;
				color:white;
			}
		}
		*/
	}
	.single-product--texture_inner {
		border:1px solid $grey-light;
		cursor: pointer;
	}
	.single-product--texture_image {
		width:100%;
		padding-bottom: 100%;
		display: block;
		background: {
			size:cover;
			position:center;
		}
	}
	.single-product--texture_name {
		text-align: center;
		font-size: 9.5px;
		font-weight: 500;
		letter-spacing: 0.9px;
		text-transform: uppercase;
		color:#7d8386;
		padding:5px 5px;
		transition:background-color 0.3s ease, color 0.3s ease;
		line-height: 1.5em;
		min-height:38px;
	}

	ul.single-product--profiles {
		li {
			margin-bottom:10px;
		}
	}

	.single-product--dynamic-image {
		margin-bottom:50px;
	}

	.single-product--mobile-link-wrap,
	.single-product--dynamic-image_mobile {
		display: block;
	}
	.single-product--mobile-link-wrap {
		margin-top: 20px;
	}
	.single-product--dynamic-image_mobile {
		margin-bottom: 6px;
	}
	.single-product--desktop-link-wrap,
	.single-product--dynamic-image_desktop {
		display: none;
	}

	.single-product--specifications-title-wrap {
		margin-bottom:15px;
	}
	
	.single-product--specifications-title {
		font-weight: 500;
		line-height: 1.89;
		font-family: $pFont;
		margin:0;
		@include media-breakpoint-down(sm) {
			font-size: 23px;
		}
	}

	.single-product--accordion-content {
		width:100%;
		max-width:500px;
		font-size: 13px;
		display: none;
		a {
			color:$primary;
			text-decoration: none;
			transition:$transition;
			&:hover {
				color:$secondary;
			}
		}
	}

	.single-product--accordion-title {
		position:relative;
		cursor: pointer;
		&:after {
			content:'\';
			@include iconFont();
			position: absolute;
			right:0;
			top:5px;
			transform:rotate(90deg);
			color:$grey-divider;
			font-size:16px;
			transition:transform 0.3s ease;
		}
		&.active {
			&:after {
				transform:rotate(-90deg);
			}
		}
	}
	.single-product--accordion {
		border-bottom:1px solid $grey-divider;
		padding-bottom:10px;
		margin-bottom:10px;
	}

	ul.single-product--resources-list {
		@include list();
		margin:20px 0;
		li {
			line-height: 1em;
			i {
				margin-right: 5px;
				vertical-align: -2px
			}
			a {
				color:$slate;
				transition:$transition;
				&:hover {
					color:$secondary;
				}
			}
		}
		@include media-breakpoint-down(sm) {
			li {
				margin-bottom: 25px;
				i {
					font-size:24px;
					vertical-align:middle;
				}
				a {
					font-size: 16px;
				}
			}
		}
	}

	.single-product--gallery {
		
	}

	.single-product--gallery-container {
		overflow:hidden;
    	position:relative;
    	padding-top: 45px;
    	&:after {
    		content:'';
    		background-image: url(../images/grey-divider_bottom.svg);
		    top: 0px;
		    left: 0;
		    width: 100%;
		    height: 81px;
		    display: block;
		    position: absolute;
		    background-size: cover;
    	}
	}


	.single-product--gallery-item {
		background: {
			size:cover;
			position:center;
			repeat:no-repeat;
		}
		padding-bottom:55.50%;
		border-left:1px solid white;
		border-right:1px solid white;
	}


	.single-product--related-products-title {
		font-family: $pFont;
		font-weight: 500;
		line-height: 1.48;
		text-align: center;
		color:$grey-dark;
		margin:50px 0 0px 0;
	}

	.single-product--related-products-slider {
		.slick-slide {
			padding: 8px;
			&.slick-current {
				.item {
					border: 3px solid $primary;
				}
			}
			&:not(.slick-current) {
				&:hover {
					.item {
						border: 3px solid $grey-light;
					}
				}
			}
		}

		.item {
			transition: border 0.3s ease;
			cursor: pointer;
			width: 100%;
			padding-bottom: 100%;
			border: 3px solid transparent;
			background: {
				size: cover;
				position: center;
				repeat: no-repeat;
			}
		}
	}

	.product--related-projects {
		.slick-arrow {
			color:#bbc2c6;
			&:hover {
				color:$primary;
			}
			&.slick-prev {
				display: block;
				padding: 0;
				text-align: center;
				left: -50px;
			}
			&.slick-next {
				display: block;
			    padding: 0;
			    text-align: center;
			    right: -50px;
			}
		}

		.post-item {
			margin-top:40px;
		}

		.post-item--product-parent-title {
			text-transform: uppercase;
			margin:25px 0 0;
		}
		.post-item--title {
			margin:10px 0;
		}
	}

	.single-product--colours-disclaimer-learn-more-text {
		background-color:$off-white;
		padding:40px;
		max-width:750px;
		p {
			line-height: 1.56;
		}
		a {
			color:$primary;
			transition:$transition;
			&:hover {
				color:$secondary;
			}
		}
	}

	.single-product--specifications-expand-wrap,
	.single-product--specifications-collapse-wrap {
		display: none;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		.single-product--wrap {
			padding-top: 100px;
		}
		.single-product--textures {
			padding-bottom: 0;
		}
		.single-product--section-divider {
			padding: 20px 0;
		}
		&.product-template-template-product {
			.single-product--texture {
				max-width:108px;
			}
		}

		.single-product--mobile-link-wrap,
		.single-product--dynamic-image_mobile {
			display: none;
		}
		.single-product--desktop-link-wrap,
		.single-product--dynamic-image_desktop {
			display: block;
		}
		.single-product--accordion-controls {
			text-align: right;
		}
		.single-product--specifications-expand-wrap {
			margin-left:10px;
		}
		.single-product--specifications-expand-wrap,
		.single-product--specifications-collapse-wrap {
			display: inline-block;
			text-align: right;
		}

		.single-product--gallery-container {
			padding-top: 0;
			padding-right: 225px;
			padding-left: 0;
			&:after {
				top: 57px;
			}
		}

	
		.single-product--gallery-slider {
			margin:100px 0 50px;
			.slick-list {
				overflow:visible!important;
			}
			.slick-next {
				right:-200px;
			}
		}

	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

		ul.single-product--profiles {
			columns:2;
		}

		ul.single-product--resources-list {
			columns:2;	
		}
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
