.block.calculator {
	position: relative;
	padding-top: 25px;
	@include topDivider('white');
	.col-md-12 {
		position:relative;
		padding-bottom: 60px;
		&:after {
			content:'';
			position:absolute;
			bottom:0;
			width:calc(100% - 55px);
			left:50%;
			transform:translateX(-50%);
			height: 1px;
			background-color: $grey-divider;
		}
	}

	
	label {
		font-weight: 500;
		line-height: 1.56;
		color:$grey-dark;
		margin-bottom: 7px;
		display: block;	
	}



	.calculator--wall_number {
		margin:0 0 15px 0;
		color:$primary;
		font-weight: 500;
		font-family: $pFont;
		position: relative;
		display: block;
		span {
			//color:$secondary;
		}
		&:after {
			content:'';
			width: 100%;
			right:0;
			height: 1px;
			background-color: $grey-divider;
			position: absolute;
			top: 50%;
			transform:translateY(-50%);
		}
	}

	.calculator--wall_number_mask {
		background-color: white;
		padding-right:15px;
	    z-index: 2;
	    position: relative;
	    display: inline-block;
	}

	.calculator--siding_type {
		margin-bottom: 20px;
	}

	.calculator--fll_width,
	.calculator--wall_height {
		display: inline-block;
		margin-bottom:15px;
	}
	
	.calculator--fll_width {
		margin-right: 20px;
	}

	.select-wrap {
		position:relative;
		display: block;
		background-color: white;
		background-color: #f2f3f4;
		max-width:270px;
		select {
			position: relative;
			border:1px solid transparent;
			appearance:none;
			display: block;
			width:100%;
			padding-left:10px;
			padding-right: 10px;
			height: 46px;
			font-family: $pFont;
			background-color: transparent;
			&:focus {
				outline:none;
				border: 1px solid $grey-light;
			}
			&.invalid {
				border:1px solid red;
			}
		}
		&:before {
			@include iconFont();
			content: "\";
			position:absolute;
			right:20px;
			top:50%;
			transform:translateY(-50%) rotate(90deg);
			color:#7b7c7f;
		}
	}

	.number-wrap {
		input {
			border:1px solid transparent;
			background-color: #f2f3f4;
			appearance:none;
			height: 46px;
			padding-left:10px;
			padding-right: 10px;
			font-family: $pFont;
			&:focus {
				outline:none;
				border: 1px solid $grey-light;
			}
			&::placeholder {
				color:rgba($navy, 1);
			}
			&.invalid {
				border:1px solid red;
			}
		}
	}

	.calculator--wall {
		margin-top:0px;
		position:relative;
		padding-left:10px;
	}

	.calculator--remove-wall {
		position:absolute;
		text-align: center;
		line-height: 20px;
		left:0;
		border-radius: 50%;
		background-color: transparent;
		color:$secondary;
		border:1px solid $secondary;
		top: 0;
		width:24px;
		height: 24px;
		text-decoration: none;
		display: block;
		font-size: 20px;
		transition:all 0.3s ease;
		&:hover {
			background-color:$primary;
			color:white;
			border-color: $primary;
		}
	}

	.calculator--add-wall-container {
		margin:40px 0;
	}
	.calculator--add-wall {
		color:$primary;
		text-decoration: none;
		font-weight: 500;
		transition:$transition;
		span {
			margin-left:5px;
			color:$secondary;
		}
		&:hover {
			color:$secondary;
		}
	}

	.calculator--submit-wrap {
		border-top:1px solid $grey-divider;
		padding-top: 40px;
	}

	.global-disclaimer {
		margin-top: 25px;
	}
	.calculator--results {
		margin-bottom: 50px;
	}

	.calculator--results_title {
		color:$grey-dark;
		font-weight: 500;
		font-family: $pFont;
		margin:35px 0 15px 0;
		font-size: 31px;
		font-weight: 500;
		line-height: 1.48;
		span {
			color:$secondary;
		}
	}

	.calculator--results_content {
		font-family: $pFont;
		font-size: 16px;
		line-height: 1.56;
		color:$grey-dark;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		padding-top:100px;
		
		.calculator--results {
			margin-bottom: 0;
		}

		.calculator--controls_wrap {
			max-width: calc(50% + 27.5px);
		}

		.calculator--wall {
			margin-top:40px;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}