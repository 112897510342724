.single.single-project {
	.project--top-wrap,
	.project--related-projects {
		background: {
			color: $off-white;
			image: url("../images/woodgrain_greywhite.svg");
			size: cover;
			position: center;
		}
	}

	.project--top-wrap {
		padding-top: 90px;
		&.no-related-projects {
			padding-bottom:90px;
		}
	}

	.project--title {
		margin: 20px 0 0 0;
		color: $slate;
	}

	.project--description {
		margin-top: 30px;
		font-size: 16px;
		line-height: 1.56;
		color: $grey-dark;
		p {
			color: $grey-dark;
			font-family: $pFont;
		}
	}

	.project--features {
		@include list();
		margin: 30px 0;
		padding: 20px 0;
		border-top: 1px solid $grey-divider;
		border-bottom: 1px solid $grey-divider;
		li {
			text-transform: uppercase;
			font-size: 11px;
			font-weight: 500;
			line-height: 2.27;
			letter-spacing: 1.1px;
			color: $grey-dark;
			a {
				transition:$transition;
				color:$primary;
				&:hover {
					color:$secondary;
				}
			}
		}
	}

	.project--challenge-solution-title {
		font-family: $pFont;
		color: $grey-dark;
		font-weight: 500;
		line-height: 1.39;
		margin: 0 0 15px 0;
	}

	.project-challenge-solution-content {
		margin-bottom: 20px;
		color: $grey-dark;
		font-size: 16px;
		line-height: 1.56;
	}

	.project--gallery.slider-nav {
		.slick-slide {
			padding: 8px;
			&.slick-current {
				.item {
					border: 3px solid $primary;
				}
			}
			&:not(.slick-current) {
				&:hover {
					.item {
						border: 3px solid $grey-light;
					}
				}
			}
		}

		.item {
			transition: border 0.3s ease;
			cursor: pointer;
			width: 100%;
			padding-bottom: 100%;
			border: 3px solid transparent;
			background: {
				size: cover;
				position: center;
				repeat: no-repeat;
			}
		}
	}

	.project--related-projects {
		padding-top: 105px;

		.slick-arrow {
			color:#bbc2c6;
			&:hover {
				color:$primary;
			}
			&.slick-prev {
				display: block;
				padding: 0;
				text-align: center;
				left: -50px;
			}
			&.slick-next {
				display: block;
			    padding: 0;
			    text-align: center;
			    right: -50px;
			}
		}
	}

	.project--related-projects-title {
		text-align: center;
		font-family: $pFont;
		font-weight: 500;
		line-height: 1.48;
		margin: 0;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
