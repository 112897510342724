.block.team-members {
	padding: 0;
	position: relative;
	padding-top:0px;
	margin-top:-30px;
	overflow: hidden;
	.team-members--background {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background-image: url("../images/team_bg.svg");
		background-size: 100%;
		background-position: top;
		background-repeat: no-repeat;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
	}

	.team-members--title {
		font-family: $pFont;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.48;
		color:$grey-dark;
		text-align: center;
		margin:0;
	}

	.load-more-button-container {
		text-align: center;
	}

	.dynamic-content {
		position:relative;
		&.loading {
			&:after {
				content:'';
				position:absolute;
				top:0;
				left:0;
				top:0;
				left:0;
				background-color:rgba(255,255,255,0.6);
				background-image:url('../images/loading.gif');
			    height: 100%;
			    width: 100%;
			    background-repeat: no-repeat;
			    background-position: center 100px;
			}
		}
	}

	.no-posts {
		margin-bottom: 250px;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		margin-top:-120px;
		padding-top: 80px;
		.team-members--results_info {
			text-align: right;
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
