.faq-selector {
	position: relative;
	padding-top: 100px;
	&.enable-wave-divider {
		@include topDivider('white');
	}

	.faq-selector--title {
		color:$primary;
	}

	.select-wrap {
		position:relative;
		display: block;
		background-color: white;
		background-color: #f2f3f4;
		max-width:270px;
		select {
			position: relative;
			border:1px solid transparent;
			appearance:none;
			display: block;
			width:100%;
			padding-left:10px;
			padding-right: 10px;
			height: 46px;
			font-family: $pFont;
			background-color: transparent;
			transition:$transition;
			&:focus {
				outline:none;
				border:1px solid $primary;
			}
		}
		&:before {
			@include iconFont();
			content: "\";
			position:absolute;
			right:20px;
			top:50%;
			transform:translateY(-50%) rotate(90deg);
			color:#7b7c7f;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.faq-selector--title {
			font-size: 38px;
		}
		
	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}