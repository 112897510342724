footer.footer-content {
	text-align: center;
	color:$grey-dark;
	p, a, td {
		font-family:$pFont;
	}

	.main-footer {
		padding-top: 30px;
		padding-bottom:0px;
		background-color: $off-white;
		a {
			color: $primary;
		}
	}

	.footer--logo-wrap {
		max-width:136px;
		width:100%;
		margin:0 auto;
		img {
			width:100%;
			display: block;
		}
	}

	.footer--addresses-wrap {
		margin-top:25px;
		border-top:1px solid $grey-divider;
		border-bottom:1px solid $grey-divider;
		padding:25px 0 5px;
	}

	.footer--addresses_title {
		margin:0 0 12px;
		font-family: $pFont;
		color:$grey-dark;
		@include media-breakpoint-down(sm) {
			font-size:18px;
		}
	}

	.footer--addresses_address {
		margin-bottom:20px;
	}


	.footer--social-icons-wrap {
		padding:30px 0;
	}

	/**
	 * Footer columns
	 */

	 .footer--column-wrap {
	 	margin-bottom: 70px;
	 }
		.footer--column_title {
			font-family: $pFont;
			color:$grey-dark;	
			margin:0 0 15px;
			padding-bottom:20px;
			border-bottom:1px solid $grey-divider;
			font-weight: 500;
		}
	

		.footer--column_link-wrap {
			display: block;
			margin-top: 15px;
		}

		.footer--final-column-link-wrap {
			display: block;
			margin-bottom:10px;
			a {
				text-decoration: none;
				position: relative;
				font-weight: 500;
				transition: all 0.3s ease;
				&:hover {
					color:$secondary;
				}
				&:after {
					content:'';
					position: absolute;
					width:100%;
					height: 2px;
					bottom:-3px;
					left:0;
					background-color: $secondary;
				}
			}
		}



	.socket {
		@include media-breakpoint-down(sm) {
			padding:10px 0 100px;
			#menu-footer-menu {
				text-align: center;
				li {
					margin-right:5px;
				}
			}
			.copyright {
				text-align: center;
				margin-bottom: 11px;
			}
		}
		background-color:$slate;
		padding:40px 0;
		text-align: center;
		.copyright {
			font-size:11px;
			color:white;
		}
	}

	.footer-links {
		p, a {
			font-weight: 500
		}
	}

	.footer-links,
	#menu-footer-menu {
		@include list();

		color: white;

		li {
			display: inline-block;
			margin:0 25px 0 0;
			&:last-child {
				margin-right: 0;
			}
			a {
				font-size: 11px;
				text-transform: uppercase;
				letter-spacing: 1.54px;
				transition: color 0.3s ease;
				color:white;
				text-decoration: none;
				font-family: $pFont;
				&:hover {
					color:$secondary;
				}
			}
		}

		a {
			font-size: 11px;
			letter-spacing: 1.54px;
			transition: color 0.3s ease;
			color:white;
			text-decoration: none;
			font-family: $pFont;

			&:hover {
				color:$secondary;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		text-align: left;

		.main-footer {
			padding-bottom:72px;	
		}

		.footer--logo-wrap {
			margin:0;
		}

		.column--addresses {
			margin-top: -50px;
		}



		 .footer--column-wrap {
		 	margin-bottom: 40px;
		 }


		.footer--social-icons-wrap {
			padding:0;
		}

		.socket {
			padding:7.5px 0;
			text-align: left;
			.copyright {
				font-size:13px;
				letter-spacing:1.26px;
				margin-bottom:0px;
			}
		}
		
		.footer-links {
			text-align: right;
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
